/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/Input";
import apiService from "services/apiService";

// Define the document type
interface Doc {
  id?: number;
  name: string;
  optional: boolean;
  tempDeleted?: boolean;
}

interface DocumentFormProps {
  selectedDocument: Doc | null;
  refreshDocuments: () => void; // Function to refresh the document list
  setSelectedDocument: any; // Reset selected document
}

// Define Yup schema for validation
const schema = yup.object().shape({
  name: yup.string().required("Document name is required"),
  optional: yup.boolean(),
});

const DocumentForm: React.FC<DocumentFormProps> = ({ selectedDocument, refreshDocuments, setSelectedDocument }) => {
  console.log("selectedDocument", selectedDocument);
  const methods = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      optional: false,
    },
  });

  const { handleSubmit, reset, control } = methods;

  useEffect(() => {
    if (selectedDocument) {
      reset({
        name: selectedDocument.name,
        optional: selectedDocument.optional,
      }); // Populate form with selected document for editing
    } else {
      reset({
        name: "",
        optional: false,
      }); // Reset form for adding new document
    }
  }, [selectedDocument, reset]);

  // Submit handler for adding or updating a document
  const onSubmit = async (data: Doc) => {
    try {
      if (selectedDocument?.id) {
        // Update document
        await apiService
          .put(`api/documentNames/update`, [{ ...data, id: selectedDocument.id }])
          .then((res) => {
            console.log("res", res);
            refreshDocuments();
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        apiService
          .post("api/documentNames/add", [data])
          .then((res) => {
            console.log("res", res);
            refreshDocuments();
          })
          .catch((err) => {
            console.log("err", err);
          });
      }

      setSelectedDocument(null); // Reset the selected document
      reset({
        name: "",
        optional: false,
      }); // Clear form
    } catch (error) {
      console.error("Error submitting document", error);
      console.log("err", error);
    }
  };

  return (
    <div className="mt-8 max-w-lg mx-auto bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
        {selectedDocument ? "Edit Document" : "Add Document"}
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <Input name="name" label="Document Name" />
          </div>

          <div className="flex items-center justify-between">
            <label className="text-sm font-medium text-gray-700">Optional</label>
            <Controller
              name="optional"
              control={control}
              render={({ field }) => (
                <input
                  type="checkbox"
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  className="rounded-md focus:ring-green-500 focus:border-green-500"
                />
              )}
            />
          </div>

          <div className="mt-8 text-center">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-200 ease-in-out transform hover:scale-105 focus:outline-none"
            >
              {selectedDocument ? "Update Document" : "Submit Document"}
            </button>
            <button
              type="reset"
              className="w-full bg-red-600 text-white font-semibold py-2 px-4 mt-2 rounded-lg shadow-md transition duration-200 ease-in-out transform hover:scale-105 focus:outline-none"
              onClick={() => {
                reset({
                  name: "",
                  optional: false,
                });
                setSelectedDocument(null);
              }}
            >
              Reset
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(DocumentForm);
