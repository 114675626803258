//  formatCurrency(price, locale, currency)
/* eslint-disable @typescript-eslint/no-explicit-any */
const formatCurrency = (value: any, currency = "INR") => {
  if (currency === "INR" || currency === "USD") {
    const locale = currency === "INR" ? "en-IN" : "en-US";
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  } else {
    return value;
  }
};

export default formatCurrency;
