import DataTable, { DataTableProps } from "components/DataTable";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const HolidaysList: React.FC<DataTableProps> = ({ data, columns, loading }) => {
  useEffect(() => {
    console.log("data, columns ", data, columns);
  }, []);
  const {t} = useTranslation();
  return <DataTable data={data} columns={columns} title={`${t("Holiday")} ${t("List")}`} loading={loading} />;
};
export default HolidaysList;
