/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent } from "react";
import { useFormContext, useController } from "react-hook-form";
import * as yup from "yup";

interface InputProps {
  name: string;
  label: string;
  type?: string;
  validationSchema?: yup.AnyObjectSchema;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value?: string | number;
  required?: boolean;
  disabled?: boolean;
  min?: any;
  max?: any;
  className? :string
}

const Input = ({
  name,
  label,
  type = "text",
  validationSchema,
  placeholder,
  onChange,
  value,
  disabled,
  required,
  min,
  max,
 
  ...rest
}: InputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field,
    fieldState: { invalid },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: {
      validate: validationSchema
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          async (value: any) => {
            try {
              await validationSchema.validate(value);
              return true;
            } catch (error: any) {
              return error.message;
            }
          }
        : undefined,
    },
  });

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let transformedValue = e.target.value;

    // Capitalize only the first character for both textarea and input
    if (transformedValue.length === 1) {
      transformedValue = capitalizeFirstLetter(transformedValue);
      e.target.value = transformedValue; // Update the event target value
    }

    field.onChange(e); // Pass the event to onChange, not the transformed value directly
    if (onChange) onChange(e);
  };

  const errMessage = errors[name] ? errors[name]?.message : undefined;

  return (
    <div className="mb-4">
      <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      {type === "textarea" ? (
        <textarea
          {...field}
          rows={4}
          placeholder={placeholder}
          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
            invalid
              ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
              : "border-gray-300"
          }`}
          onChange={handleChange}
          value={value || field.value}
          {...rest}
        ></textarea>
      ) : (
        <input
          {...field}
          type={type}
          placeholder={placeholder}
          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
            invalid
              ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
              : "border-gray-300"
          }`}
          onChange={handleChange}
          value={value || field.value}
          disabled={disabled ? disabled : false}
          min={min}
          max={max}
          {...rest}
        />
      )}
      {errMessage && <p className="text-red-500 mt-1 text-sm font-medium">{errMessage as React.ReactNode}</p>}
    </div>
  );
};

export default Input;
