/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useEffect, useState } from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import apiService from "services/apiService";

export default function UpdateDocs({ onClose }: any) {
  const [documentData, setDocuments] = useState<any>();
  const [files, setFiles] = useState<{ [key: string]: File | null }>({});
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    apiService
      .get("api/documentNames/list")
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const handleFileUpload = (name: string, file: File | null) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [name]: file,
    }));
  };

  const removeFile = (name: string) => {
    setFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[name];
      return updatedFiles;
    });
  };

  const onSubmit = async () => {
    setUploading(true);
    const formData = new FormData();
    Object.keys(files).forEach((name) => {
      const file = files[name];
      if (file) {
        formData.append(name, file);
      }
    });

    try {
      await apiService.post("api/Uploaddocuments/upload", formData);
      onClose();
    } catch (err) {
      console.log("err", err);
    } finally {
      setUploading(false);
    }
  };

  const availableDocs = documentData && documentData.filter((doc: any) => !doc.tempDeleted);

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white rounded-lg shadow-lg space-y-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Upload Images & Documents</h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {availableDocs &&
          availableDocs.map((doc: any) => (
            <div
              key={doc.id}
              className="p-4 border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <label className="block text-sm font-medium text-gray-700 mb-2">{doc.name}</label>
              <input
                type="file"
                accept="image/jpeg,image/jpg,image/png,image/bmp,image/tiff"
                onChange={(e) => handleFileUpload(doc.name, e.target.files ? e.target.files[0] : null)}
                className="w-full text-sm text-gray-700 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border file:border-gray-300 file:bg-gray-50 file:text-gray-700 file:cursor-pointer file:hover:bg-gray-100"
              />
              {files[doc.name] && (
                <div className="relative mt-4">
                  <img
                    src={URL.createObjectURL(files[doc.name]!)}
                    alt={`preview-${doc.name}`}
                    className="w-full max-w-[200px] h-auto object-cover rounded-lg shadow-sm"
                    loading="lazy"
                    style={{ maxHeight: 200 }}
                  />
                  <button
                    onClick={() => removeFile(doc.name)}
                    className="absolute top-1 right-1  p-1 rounded-full shadow-sm hover:bg-gray-100"
                    aria-label="Remove file"
                  >
                    <AiOutlineCloseSquare className="text-gray-600 w-6 h-6" />
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>

      <div className="flex justify-center mt-6">
        <button
          className={`w-full sm:w-auto bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg shadow-lg transition duration-200 ease-in-out transform ${uploading ? "bg-blue-400 cursor-not-allowed" : "hover:scale-105"}`}
          onClick={onSubmit}
          disabled={Object.keys(files).length === 0 || uploading}
        >
          {uploading ? "Uploading..." : "Upload Documents"}
          {uploading && <span className="ml-2 spinner-border spinner-border-sm"></span>}
        </button>
      </div>
    </div>
  );
}
