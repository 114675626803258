/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import LeavesList from "./LeavesList";
import { Row } from "components/DataTable";
import leaveManager from "services/Leave/leaveManager";

import { FaEdit, FaEye, FaRegTrashAlt } from "react-icons/fa";
import Modal from "components/Modal";
import DeletePopup from "components/DeletePopup/deletePopup";
import GenericModal from "components/Modal";
import GenericToaster from "components/Toaster";
import ViewLeave from "./ViewLeave/ViewLeave";
import AddLeave from "./AddLeave/AddLeave";
import LeaveDetails from "./LeaveDetails";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import apiService from "services/apiService";
import { useAppContext } from "contexts";
import { storageService } from "services/storageService";

export default function Leaves() {
  const [leaves, setLeaves] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [showSuccessToaster, setSuccessToaster] = useState(false);
  const [showErrorToaster, setErrorToaster] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const { user, userRole, email } = useAppContext();
  const handleEdit = (row: any): void => {
    setUpdateModal(true);
    setSelectedRow(row);
  };
  const handleDelete = (row: any): void => {
    setSelectedRow(row);
    setDeleteModal(true);
  };

  const handleView = (row: any): void => {
    setViewModal(true);
    setSelectedRow(row);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const onUpdated = () => {
    setOpenModal(false);
    getLeaveData();
    setSuccessToaster(true);
    setMessage(" Leave is Updated Successfully");
  };

  const handleAddBtnClick = () => {
    setAddModal(true);
  };

  const deleteRow = async (id: any) => {
    setDeleteModal(false);
    leaveManager
      .deleteLeave(id)
      .then(async (res) => {
        if (res.status === 200) {
          setSuccessToaster(true);
          setMessage("Leave is Deleted successfully");
          await getLeaveData();
        } else {
          setErrorToaster(true);
          setMessage(res.statusText || "Something went wrong");
        }
      })
      .catch((err) => {
        setErrorToaster(true);
        setMessage(err || "Something went wrong");
      });
  };

  const closeToaster = () => {
    setErrorToaster(false);
    setSuccessToaster(false);
  };

  const tableColumns = [
    // { key: "id", label: `ID`, bold: true },
    { key: "email", label: `User Email`, bold: true },
    {
      key: "leaveFrom",
      label: `${t("leave.leaveFrom")}`,
      type: "Date",
      bold: true,
    },
    {
      key: "leaveTo",
      label: `${t("leave.leaveTo")}`,
      type: "Date",
      bold: true,
    },
    { key: "leaveType", label: `${t("leave.leaveType")}` },
    { key: "unit", label: `${t("leave.unit")}`, bold: true },
    // { key: "reason", label: `${t("leave.reason")}` },
    // { key: "month", label: `${t("leave.month")}`, bold: true },
    {
      key: "status",
      label: `${t("leave.status")}`,
      bold: true,
      render: (row: any) => {
        if (row === "Approved") {
          return (
            <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
              {row}
            </span>
          );
        } else if (row === "Rejected") {
          return (
            <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
              {row}
            </span>
          );
        } else {
          return (
            <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">
              {row}
            </span>
          );
        }
      },
    },
    {
      key: "actions",
      label: `${t("leave.actions")}`,
      excludeExport: true,
      actions: (row: any) => (
        <div className="flex flex-start">
          {row?.status === "Submitted" && (
            <span onClick={() => handleEdit(row)} className="btn-primary  px-2 py-2 mr-3 rounded-lg">
              <FaEdit className="text-white" />
            </span>
          )}
          <span onClick={() => handleView(row)} className="btn-primary  px-2 py-2 mr-3 rounded-lg">
            <FaEye className="text-white" />
          </span>
          {row?.status === "Submitted" && (
            <span onClick={() => handleDelete(row)} className="btn-red px-2 py-2 mr-2 rounded-lg">
              <FaRegTrashAlt className="text-white" />
            </span>
          )}
        </div>
      ),
    },
  ];

  const getUrl = () => {
    const year = new Date().getFullYear();
    const da = storageService.getItem("user");
    const aaa = da && JSON.parse(da);
    if (aaa.role === 2 || aaa.role === 3) {
      return `/api/leave/data/${year}`;
    } else {
      return `/api/leave/data/${year}?email=${aaa.email}`;
    }
  };
  const getLeaveData = async () => {
    setLoading(true);
    const url = getUrl();
    console.log("url", url);
    apiService
      .get(url)
      .then((res) => {
        const leavesResponses: Row[] = res.data.map((row: any) => ({
          ...row,
        }));

        leavesResponses.sort((a: any, b: any) => b.id - a.id);
        console.log("leavesResponses ", leavesResponses);
        setLeaves(leavesResponses);
      })
      .catch((error) => {
        console.error("Error fetching Reimbursement list:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDataAdded = () => {
    setAddModal(false);
    getLeaveData();
    setSuccessToaster(true);
    setMessage("Leave is Added Successfully");
  };

  const onError = () => {
    setErrorToaster(true);
    setMessage("Something went wrong");
  };

  useEffect(() => {
    getLeaveData();
  }, []);
  const BreadCrumbList = [
    { name: "Leave", link: "#" },
    { name: "list", link: "#" },
  ];

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <LeavesList data={leaves} columns={tableColumns} loading={loading} onAdd={handleAddBtnClick} />
      {updateModal && (
        <Modal isOpen={updateModal} onClose={() => setUpdateModal(false)} header="Update Leave">
          <LeaveDetails
            onUpdate={onUpdated}
            selectedRow={selectedRow}
            onClose={() => setUpdateModal(false)}
            onError={onError}
          />
        </Modal>
      )}
      {deleteModal && (
        <GenericModal isOpen={deleteModal} onClose={() => setDeleteModal(false)} header="Delete Leave">
          <DeletePopup handleDelete={deleteRow} onClose={() => setDeleteModal(false)} selectedRow={selectedRow} />
        </GenericModal>
      )}
      {addModal && (
        <GenericModal isOpen={addModal} onClose={() => setAddModal(false)} header="Apply for Leave">
          <AddLeave onSuccess={onDataAdded} onError={onError} onClose={() => setAddModal(false)} />
        </GenericModal>
      )}
      {viewModal && (
        <GenericModal isOpen={viewModal} onClose={() => setViewModal(false)} header="Leave Details">
          <ViewLeave selectedRow={selectedRow} />
        </GenericModal>
      )}
      {(showSuccessToaster || showErrorToaster) && (
        <GenericToaster
          message={message}
          onClose={closeToaster}
          open={showSuccessToaster || showErrorToaster}
          severity={showErrorToaster ? "error" : "info"}
        />
      )}
    </>
  );
}
