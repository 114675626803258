/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/Input";
import Select from "react-select";
import apiService from "services/apiService";

const schema = yup.object().shape({
  emails: yup.string().required("Employees  are required"),
  clientContactName: yup.string().required("Client Name is requied"),
  clientContactNumber: yup
    .string()
    .nullable()
    .optional()
    .matches(
      /^(\+91[-\s]?)?[789]\d{9}$|^(\+1[-\s]?)?\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/,
      "Mobile number must be a valid Indian or US number"
    ),
  manager: yup.string().required("Manager is Required"),
  name: yup
    .string()
    .required("Name is Required ")
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),
  projectDetail: yup.string().required("Project Details is Required"),
  startDate: yup.string().required("Start Date is Required"),
});

interface AddProps {
  onClose: () => void;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

interface OptionProps {
  value: string;
  label: string;
}
export default function AddProject({ onClose, onSuccess, onError }: AddProps) {
  const methods = useForm({ resolver: yupResolver(schema) });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit: SubmitHandler<any> = (data: any) => {
    console.log("data", data);
    apiService
      .post("api/project", data)
      .then((res) => {
        console.log("create response", res);
        if (res.status === 200) {
          onSuccess("Project is Added successfully");
        } else {
          onError(res.statusText || "Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err", err);
        onError(err || "Something went wrong");
      })
      .finally(() => {
        onClose();
      });
  };
  const [data1, setData] = useState<OptionProps[]>(); 
    const [startDate, setStartDate] = useState("");
  const [complitionDate, setComplitionDate] = useState("");

  
     const onStartFromChanged = (e: any) => {
    setStartDate(e.target.value);
  };

  const onEndToChanged = (e: any) => {
    setComplitionDate(e.target.value);
  };

// the purpose is project ComplitionDate Field does not allows Date before Project Starting Date
  
  useEffect(() => {
    if (startDate && complitionDate) {
     
      const startFrom: Date = new Date(startDate);
      const complition: Date = new Date(complitionDate);
      let days = 0;
      for (let d = new Date(startFrom); d <= complition; d.setDate(d.getDate() + 1)) {
        if (d.getDay() !== 6 && d.getDay() !== 0) {
          // 6 is Saturday, 0 is Sunday
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          days++;
        }
      }
    }
  }, [startDate, complitionDate]);
  const minLeaveToDate = startDate ? new Date(startDate).toISOString().split("T")[0] : ""; 

  // ----------------------------

  useEffect(() => {
    apiService
      .get("api/users")
      .then((res) => {
        console.log("usersss", res.data);
        const getEmailOptions = (emailArray: any[]) => {
          return emailArray.map((email: any) => ({
            value: email.email,
            label: email.displayName,
          }));
        };
        const emailOptions = getEmailOptions(res.data);
        setData(emailOptions);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid gap-4 grid-cols-2">
            {/* Here is Added required for every input field  */}
            <Input name="name"  label={`Project Name`} placeholder={`Enter Project Name`} required/>
            {/* <Input name="manager" label="Project Manager" placeholder={`Enter Project Manager`} /> */}
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Select Manager <span className="text-red-500"> *</span></label>
              <Select
                {...methods.register("manager", { required: true })}
                options={data1}
                
                placeholder="Select Employee"
                required
                className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                onChange={(selectedOption) => {
                  selectedOption && methods.setValue("manager", selectedOption.label);
                }}
              />
              {methods.formState.errors.manager && (
                <span className="text-red-500 text-sm mt-1">{methods.formState.errors.manager.message}</span>
              )}
            </div>
           {/* Here is Added "required" for every input field  */}
            <Input name="clientContactName"  label="Client Name" placeholder={`Enter Client Name`} required/>
            <Input
              name="clientContactNumber"
              label=" Client Mobile Number"
              placeholder={`Enter Mobile Number`}
              type="number"
              required
            />
            <Input name="startDate" label="Start Date" type="date" value={startDate} onChange={onStartFromChanged} required />
            <Input name="completionDate" label="Completion Date" type="date" value={complitionDate} onChange={onEndToChanged} min={minLeaveToDate} required />
          </div>
          <div className="mb-4" >
            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Select Employee <span className="text-red-500"> *</span></label>
            <Select
              isMulti
             
              {...methods.register("emails", { required: true })}
              options={data1}
              
              placeholder="Select Employee"
              className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
              onChange={(selectedOptions) => {
                const commaSeparatedValues = selectedOptions.map((option) => option.value).join(",");
                methods.setValue("emails", commaSeparatedValues);
              }}
              
            />
            {methods.formState.errors.emails && (
              <span className="text-red-500 text-sm mt-1">{methods.formState.errors.emails.message}</span>
            )}
          </div>
          <Input name="projectDetail" label="Project Detail" type="textarea" required />
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 text-white px-8 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
