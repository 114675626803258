import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface EditorProps {
  value: string;
  onChange: (value: string) => void;
}

const Editor: React.FC<EditorProps> = ({ value, onChange }) => {
  const [editorHtml, setEditorHtml] = useState(value);

  const handleChange = (html: string) => {
    setEditorHtml(html);
    onChange(html);
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
      ["blockquote", "code-block"],
      [{ script: "sub" }, { script: "super" }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      ["clean"],
    ],
  };
  const editorStyles = {
    minHeight: "200px",
    border: "2px solid #ccc",
    borderRadius: "8px",
  };

  return <ReactQuill theme="snow" value={editorHtml} onChange={handleChange} modules={modules} style={editorStyles} />;
};

export default Editor;
