import React from "react";
import { FcAlarmClock, FcCalendar, FcList } from "react-icons/fc";
import { dateFormate } from "util/Util";
// import { FaEdit } from "react-icons/fa";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function EventInfo({ selectedEvent, projects }: any) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = projects.filter((project: any) => project.projectId === selectedEvent.projectId);
  return (
    <div className="">
      {/* <div className="flex space-x-2">
        <FaEdit
          onClick={() => {
            console.log("edit");
          }}
          className="cursor-pointer text-green-500"
        />
      </div> */}
      <p className="text-l text-gray-600 flex items-center mb-2	">
        <FcList size={20} className="me-2" /> Project Name : {data[0].name}
      </p>
      <p className="text-l text-gray-600 flex items-center mb-2	">
        <FcAlarmClock size={20} className="me-2" /> Hours : {selectedEvent.hour}
      </p>
      <p className="text-l text-gray-600 flex items-center	mb-2">
        <FcCalendar size={20} className="me-2" /> Date : {dateFormate(selectedEvent.start)}
      </p>
      <div className="flex items-center justify-between mb-4">
        <textarea
          rows={4}
          className="block p-2.5 w-full text-lg text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
          value={selectedEvent.title}
          disabled
        />

        {/* <h2 className="text-xl font-bold break-words">{selectedEvent.title}</h2> */}
        <div className="flex space-x-2">
          {/* <FaEdit
            onClick={() => {
              console.log("edit");
            }}
            className="cursor-pointer text-green-500"
          /> */}
        </div>
      </div>
      <p className="text-gray-600 truncate">{selectedEvent.description}</p>
      {/* <p className="text-l text-gray-600 flex items-center mb-2	">
        <FcSettings size={20} className="me-2" /> Project Id : {selectedEvent.projectId}
      </p> */}

      {/* <div className="mt-4">
        <p className="text-sm text-gray-400 flex items-center me-2">
          {" "}
          <FaRegClock className="me-2" />
          Start: {moment(selectedEvent.start?.toLocaleString()).format("hh:mm A")}
        </p>
        <p className="text-sm text-gray-400 flex items-center me-2">
          {" "}
          <FaClock className="me-2" />
          End: {moment(selectedEvent.end).format("hh:mm A")}
        </p>
      </div> */}
    </div>
  );
}

export default React.memo(EventInfo);
