import { AxiosResponse } from "axios";
import apiService from "services/apiService";
import { AssetHistoryResponse, InventoryPayload, InventoryResponse, UpdateInventory } from "./inventoryInterface";

class InventoryManager {
    private url: string;
    constructor() {
        this.url = 'api/assets';
    }

    async getInventoryData(): Promise<AxiosResponse<InventoryResponse>> {
        return await apiService.get(this.url);
    }

    async getAssetHistory(id: number): Promise<AxiosResponse<Array<AssetHistoryResponse>>> {
        return await apiService.get(`${this.url}/history/${id}`);
    }

    async addInventoryData(payload: InventoryPayload): Promise<AxiosResponse> {
        return await apiService.post(this.url, payload);
    }

    async updateInventory(payload: UpdateInventory): Promise<AxiosResponse<UpdateInventory>> {
        return await apiService.put(this.url, payload);
    }

    async deleteInventory(id: number): Promise<AxiosResponse> {
        return await apiService.delete(`${this.url}/${id}`);
    }

    async uploadCSV(payload: FormData): Promise<AxiosResponse> {
        return await apiService.post(`${this.url}/uploadAssetCsv`, payload);
    }
}

const inventoryManager = new InventoryManager();
export default inventoryManager;