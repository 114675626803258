/* eslint-disable @typescript-eslint/no-explicit-any */
import DataTable from "components/DataTable";
import GenericModal from "components/Modal";
import React, { useState } from "react";
import AddReimbursement from "./AddReimbursement/AddReibmursement";
import { useTranslation } from "react-i18next";

interface IProps {
  data: any;
  columns: any;
  itemsPerPage?: number;
  title?: string;
  loading?: boolean;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

const ReimbursementList: React.FC<IProps> = ({
  data,
  columns,
  loading,
  onSuccess,
  onError,
}) => {
  const [openModal, setOpenModel] = useState(false);
  const { t } = useTranslation();

  const handleAddBtnClick = () => {
    setOpenModel(true);
  };

  const handleModalClose = () => {
    setOpenModel(false);
  };

  return (
    <>
      {data && (
        <DataTable
          data={data}
          columns={columns}
          title={t("reimbursement.header")}
          onAddBtnClick={handleAddBtnClick}
          loading={loading}
        />
      )}
      <GenericModal
        isOpen={openModal}
        onClose={handleModalClose}
        header="Add Reimbursement"
      >
        <AddReimbursement
          onClose={() => setOpenModel(false)}
          onAddSuccess={onSuccess}
          onAddError={onError}
        />
      </GenericModal>
    </>
  );
};
export default ReimbursementList;
