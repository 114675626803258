/* eslint-disable @typescript-eslint/no-explicit-any */
import ThemeSwitcher from "components/ThemeSwitcher";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import Items from "./SideBarItems";
import { useTranslation } from "react-i18next";
import { storageService } from "services/storageService";
import apiService from "services/apiService";
import { useAppContext } from "contexts/useAppContext";
import Image from "assets/avatar.png";
import { IoLogOut } from "react-icons/io5";
import GenericModal from "components/Modal";
import pkg from "../../../package.json";
import { Dropdown } from "flowbite-react";
import { CgProfile } from "react-icons/cg";

export default function Sidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setUser, setUserRole, userRole, user, setEmail } = useAppContext();
  const [logout, setLogout] = useState(false);
  const [isUserActive, setIsUserActive] = useState<boolean>(true);

  const handleLogout = () => {
    storageService.removeItem("accessToken");
    navigate("/login");
  };

  useEffect(() => {
    apiService
      .get("api/user/me")
      .then((res) => {
        const Data = res.data;
        // console.log("Data :>> ", Data);
        // delete Data.userProjectWithAssignedDate;
        if (res.data.userStatus === "ACTIVE") {
          storageService.setItem("user", JSON.stringify(Data));
          // res.data.role = 1;
          setUser(res.data);
          setUserRole(res.data.role);
          setEmail(res.data.email);
        } else {
          setIsUserActive(false);
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }, []);
  const [filteredItems, setFilterItems] = useState<any>();
  useEffect(() => {
    const filterData = Items.filter((item) => item.roles.includes(userRole));
    setFilterItems(filterData);
  }, [userRole]);

  return (
    <>
      {logout && (
        <GenericModal isOpen={logout} onClose={() => setLogout(false)} header="LogOut">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to log out?
              </h3>
              <button
                data-modal-hide="popup-modal"
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                onClick={handleLogout}
              >
                Yes, Im sure
              </button>
              <button
                data-modal-hide="popup-modal"
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={() => setLogout(false)}
              >
                No, cancel
              </button>
            </div>
          </div>
        </GenericModal>
      )}
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <Link to="/admin/profile" className="flex ms-2 md:me-24">
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  {t("header")}
                </span>
              </Link>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ms-3">
                <ThemeSwitcher />
                <Dropdown
                  label=""
                  renderTrigger={() => (
                    <img
                      className="w-8 h-8 rounded-full bg-white-100"
                      src={user ? user.imageUrl || Image : Image}
                      alt="user photo"
                      loading="lazy"
                    />
                  )}
                >
                  <Dropdown.Header>
                    <span className="block text-sm"> {user && user.displayName}</span>
                    <span className="block truncate text-sm font-medium"> {user && user.email}</span>
                  </Dropdown.Header>
                  <Dropdown.Item onClick={() => navigate("profile")} icon={CgProfile}>
                    My Profile
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => setLogout(true)} icon={IoLogOut}>
                    Sign out
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-2 font-medium">
            {filteredItems &&
              filteredItems.map((res: any) => (
                <li key={res.label}>
                  <NavLink
                    to={`/admin/${res.url}`}
                    className={({ isActive }: any) =>
                      `flex items-center p-2 text-base rounded-lg group ${
                        isActive
                          ? "text-gray-900 bg-gray-100 dark:text-gray-200 dark:bg-gray-700"
                          : "text-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700"
                      }`
                    }
                  >
                    <res.icon
                      className="w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      aria-hidden="true"
                    />
                    <span className="ms-3 ml-3">{t(res.label)}</span>
                  </NavLink>
                </li>
              ))}
            <li
              key={"logout"}
              className="p-2 text-base cursor-pointer text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700"
              onClick={() => setLogout(true)}
            >
              <button
                // to={`/login`}
                className="flex items-center "
              >
                <IoLogOut
                  className="w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                />
                <span className="ms-3 ml-3">{t("Logout")}</span>
              </button>
            </li>
          </ul>

          <div className="center" style={{ marginTop: 20 }}>
            <p className="text-gray-500">Version {pkg.version}</p>
          </div>
        </div>
      </aside>

      <div className="p-5 bg-gray-50  sm:ml-64">
        <div className="p-4 bg-white border-2 border-gray-200  rounded-lg dark:border-gray-700 layout-top">
          {isUserActive && <Outlet />}
          {!isUserActive && (
            <GenericModal
              isOpen={!isUserActive}
              onClose={() => {
                console.log("logout now :>>>");
              }}
              header="User Info"
            >
              <div className="relative bg-white rounded-lg shadow dark:bg-red-700">
                <div className="p-4 md:p-5 text-center">
                  <svg
                    className="mx-auto mb-4 text-red-400 w-12 h-12 dark:text-red-200"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-red-500 dark:text-red-400">
                    <span className="font-medium">Info alert!</span> Your User Id is Removed ,Please Contact Admin.
                  </h3>
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                    onClick={handleLogout}
                  >
                    LogOut
                  </button>
                </div>
              </div>
            </GenericModal>
          )}
        </div>
      </div>
    </>
  );
}
