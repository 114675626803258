/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, ReactNode } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/Input";
import Select from "react-select";
import apiService from "services/apiService";
import Loader from "components/Loader";

const schema = yup.object().shape({
  emails: yup.string().required("Employees  are required"),
  clientContactName: yup.string().required("Client Name is requied"),
  clientContactNumber: yup.string().notRequired(),
  manager: yup.string().required("Manager is Required"),
  name: yup
    .string()
    .required("Name is Required ")
    .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),
  projectDetail: yup.string().required("Project Details is Required"),
  startDate: yup.string().required("Start Date is Required"),
});

interface AddProps {
  onClose: () => void;
  data: any;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

interface OptionProps {
  value: string;
  label: string;
}
export default function EditProject({ onClose, data, onSuccess, onError }: AddProps) {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      clientContactNumber: parseInt(data.clientContactNumber),
      ...data,
    },
  });
  console.log("data", data);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit: SubmitHandler<any> = (data: any) => {
    console.log(data);
    apiService
      .put("api/project", data)
      .then((res) => {
        console.log("create response", res);
        if (res.status === 200) {
          onSuccess("Project is Updated successfully");
        } else {
          onError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err", err);
        onError("Something went wrong");
      })
      .finally(() => {
        onClose();
      });
  };
  const [data1, setData] = useState<OptionProps[]>();
  useEffect(() => {
    setLoading(true);
    apiService
      .get("api/users")
      .then((res) => {
        setLoading(false);
        const getEmailOptions = (emailArray: any[]) => {
          return emailArray.map((email: any) => ({
            value: email.email,
            label: email.displayName,
          }));
        };
        const emailOptions = getEmailOptions(res.data);
        setData(emailOptions);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const [selectedValues, setSelectedValues] = useState<OptionProps[]>();
  const [selected, setSelected] = useState<OptionProps>();
  useEffect(() => {
    if (data1) {
      const selectedOptions = data1.filter((option) => data.emails.includes(option.value));
      setSelectedValues(selectedOptions);
      const setManager = data1 && data1.find((option) => option.value === data.manager);
      setSelected(setManager);
    }
  }, [data1]);
  useEffect(() => {
    if (data.startDate) methods.setValue("startDate", new Date(data.startDate).toISOString().split("T")[0]);
    if (data.completionDate) {
      methods.setValue("completionDate", new Date(data.completionDate).toISOString().split("T")[0]);
    }
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid gap-4 grid-cols-2">
              <Input name="name" label="Project Name" placeholder={`Enter Project Name`} required/>
              {/* <Input name="manager" label="Project Manager" placeholder={`Enter Project Manager`} /> */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Select Manager <span className="text-red-500"> *</span></label>
                <Select
                  {...methods.register("manager", { required: true })}
                  options={data1}
                  placeholder="Select Employee"
                  className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  onChange={(selectedOption) => {
                    selectedOption && methods.setValue("manager", selectedOption.label);
                    if (selectedOption) setSelected(selectedOption);
                  }}
                  // defaultValue={data1 && data1.find((option) => option.value === data.manager)}
                  value={selected}
                />
                {methods.formState.errors.manager && (
                  <span className="text-red-500 text-sm mt-1">
                    {methods.formState.errors.manager.message as ReactNode}
                  </span>
                )}
              </div>
              <Input name="clientContactName" label="Client Name" placeholder={`Enter Client Name`} required />
              <Input
                name="clientContactNumber"
                required
                label=" Client Mobile Number"
                placeholder={`Enter Mobile Number`}
                type="number"
              />
              <Input name="startDate" label="Start Date" type="date" required/>
              <Input name="completionDate" label="Completion Date" type="date" required/>
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Select Employee <span className="text-red-500"> *</span></label>
              <Select
                isMulti
                {...methods.register("emails")}
                options={data1}
                placeholder="Select Employee"
                className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                onChange={(selectedOptions) => {
                  const newSelectedValues = Array.isArray(selectedOptions) ? selectedOptions : [];
                  const commaSeparatedValues = newSelectedValues.map((option) => option.value).join(",");
                  methods.setValue("emails", commaSeparatedValues);
                  setSelectedValues(newSelectedValues);
                }}
                // defaultValue={data1 && data1.filter((option) => data.emails.includes(option.value))}
                value={selectedValues}
              />
              {methods.formState.errors.emails && (
                <span className="text-red-500 text-sm mt-1">
                  {methods.formState.errors.emails.message as ReactNode}
                </span>
              )}
            </div>
            <Input name="projectDetail" label="Project Detail" type="textarea" required/>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-blue-500 text-white px-8 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
              >
                Submit
              </button>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
}
