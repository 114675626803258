/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import UploadHolidays from "./UploadHolidays";
import UploadInvetories from "./UploadInvetories";
import { BreadCrumb } from "components/BreadCrumb";
import DragnDrop from "components/DragandDropFile/DragnDrop";
import inventoryManager from "services/Inventory/inventoryManager";
import holidayManager from "services/Holiday/holidayManager";
import GenericToaster from "components/Toaster";
import { useTranslation } from "react-i18next";

export default function UploadCsv() {
  const [inventorybtn, setInventoryBtn] = useState(true);
  const [holidayBtn, setHolidayBtn] = useState(false);
  const [showSuccessToaster, setSuccessToaster] = useState(false);
  const [showErrorToaster, setErrorToaster] = useState(false);
  const [message, setMessage] = useState("Something went wrong");

  const BreadCrumbList = [
    { name: "Upload", link: "#" },
    { name: "doc", link: "#" },
  ];

  const handleHoliday = () => {
    setHolidayBtn(true);
    setInventoryBtn(false);
  };

  const handleInventory = () => {
    setInventoryBtn(true);
    setHolidayBtn(false);
  };

  const uploadFile = async (file: any) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file[0], file[0].name);
      try {
        let res: any;
        if (inventorybtn) {
          res = await inventoryManager.uploadCSV(formData);
        } else if (holidayBtn) {
          res = await holidayManager.uploadHolidayCSV(formData);
        }
        if (res.status === 200) {
          setSuccessToaster(true);
          setMessage("Uploaded successfully");
        } else {
          setErrorToaster(true);
        }
      } catch (err) {
        console.log(`something went wrong: `);
      }
    }
  };

  const closeToaster = () => {
    setErrorToaster(false);
    setSuccessToaster(false);
  };

  const {t} = useTranslation();

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />

      <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select tab
          </label>
          <select
            id="tabs"
            className="bg-gray-50 border-0 border-b border-gray-200 text-gray-900 text-sm rounded-t-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option>{t("Inventory")}</option>
            <option>{t("Holiday")}</option>
          </select>
        </div>
        <ul
          className="hidden text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg sm:flex dark:divide-gray-600 dark:text-gray-400 rtl:divide-x-reverse"
          id="fullWidthTab"
          data-tabs-toggle="#fullWidthTabContent"
          role="tablist"
        >
          <li className="w-full">
            <button
              id="stats-tab"
              data-tabs-target="#stats"
              type="button"
              role="tab"
              aria-controls="stats"
              aria-selected="true"
              className="inline-block w-full p-4 rounded-ss-lg bg-gray-50 hover:bg-gray-100 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600"
              onClick={handleInventory}
            >
              {t("Inventory")}
            </button>
          </li>
          <li className="w-full">
            <button
              id="about-tab"
              data-tabs-target="#about"
              type="button"
              role="tab"
              aria-controls="about"
              aria-selected="false"
              className="inline-block w-full p-4 bg-gray-50 hover:bg-gray-100 focus:outline-none dark:bg-gray-700 dark:hover:bg-gray-600"
              onClick={handleHoliday}
            >
              {t("Holiday")}
            </button>
          </li>
        </ul>
        <div
          id="fullWidthTabContent"
          className="border-t border-gray-200 dark:border-gray-600"
        >
          <div
            className="hidden p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800"
            id="stats"
            role="tabpanel"
            aria-labelledby="stats-tab"
          >
            <UploadInvetories />
          </div>
          <div
            className="hidden p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800"
            id="about"
            role="tabpanel"
            aria-labelledby="about-tab"
          >
            <UploadHolidays />
          </div>
          <div>
            <DragnDrop
              header={inventorybtn ? "Inventories" : "Holidays"}
              onFileSelected={uploadFile}
            />
          </div>
        </div>
      </div>
      {(showSuccessToaster || showErrorToaster) && (
        <GenericToaster
          message={message}
          onClose={closeToaster}
          open={showSuccessToaster || showErrorToaster}
          severity={showErrorToaster ? "error" : "info"}
        />
      )}
    </>
  );
}
