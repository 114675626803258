/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/Input";
// import inventoryManager from "services/Inventory/inventoryManager";
// import { InventoryPayload } from "services/Inventory/inventoryInterface";
import Select from "react-select";
import apiService from "services/apiService";
import { inventoryOptions } from "./inventoryValues";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { sortByKey } from "util/sortOptions";
import { useToaster } from "contexts/toasterContext";
// import { Label } from "flowbite-react";

interface OptionProps {
  value: string;
  label: string;
}

const schema = yup.object().shape({
  assign: yup.string().required("Assign is required"),
  type: yup.string().required("Type is required"),
  status: yup.string().required("Status is required"),
  owner: yup.string().required("Owner is required"),
  location: yup.string().required("Location is required"),
  model: yup.string().required("Model is required"),
  identity: yup.string(),
  brand: yup.string().required("Brand is required"),
  assignDate: yup.string().required("Assign Date is required"),
  purchaseDate: yup.string().required("Purchase Date is required"),
  releaseDate: yup.string().required("Release Date is required"),
  asset_type: yup.string().required("Inventory type is required"),
  userEmail: yup.string().required("User Email is required"),
  cost: yup.number().when("asset_type", {
    is: (val: string) => val === "Own",
    then: (schema) => schema.min(0, "Cost must be a non-negative number"),
    otherwise: (schema) => schema.notRequired(),
  }),
  depreciationRate: yup.number().when("asset_type", {
    is: (val: string) => val === "Own",
    then: (schema) => schema.min(0, "Depreciation Rate must be a non-negative number"),
    otherwise: (schema) => schema.notRequired(),
  }),
  rentAmount: yup.number().when("asset_type", {
    is: (val: string) => val === "Rental",
    then: (schema) =>
      schema
        .required("Rental Amount is required")
        .typeError("Please enter a valid number for rent amount")
        .min(0, "Rent Amount must be a non-negative number"),
    otherwise: (schema) => schema.notRequired(),
  }),
  rentStartDate: yup.string().when("asset_type", {
    is: (val: string) => val === "Rental",
    then: (schema) => schema.required("Assign Date is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  issueDate: yup.string().when("asset_type", {
    is: (val: string) => val === "Sample",
    then: (schema) => schema.required("Issued Date is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  returnDate: yup.string().when("asset_type", {
    is: (val: string) => val === "Sample",
    then: (schema) => schema.required("Return Date is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

interface AddProductProps {
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setRefresh: any;
  refresh: number;
}

const AddProduct = ({ onClose, setRefresh, refresh }: AddProductProps) => {
  const methods = useForm({ resolver: yupResolver(schema) });
  const [option, setOption] = useState("");
  const [data, setData] = useState<OptionProps[]>();
  const [device, setDevice] = useState();
  const [step, setStep] = useState(0);
  const [images, setImages] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>();
  const [assetType, setAssetType] = useState<any>();
  const [status, setStatus] = useState<any>();
  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);
  const { addToast } = useToaster();
  const handleImageUpload = (files: FileList | null) => {
    if (files) {
      const fileArray = Array.from(files);
      if (fileArray.length + images.length <= 3) {
        setImages((prevImages) => [...prevImages, ...fileArray]);
        setErrorMessage("");
      } else {
        setErrorMessage("You can upload a maximum of 3 images.");
      }
    }
  };
  const removeImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  const steps = [
    <div key="step1" className="grid gap-4 grid-cols-3">
      <Input name="model" label="Model" placeholder="Enter Model" required />
      <Input name="brand" label="Brand" placeholder="Enter Brand" required />
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Device Type</label>
        <Select
          {...methods.register("type", { required: true })}
          options={sortByKey(inventoryOptions, "label")}
          placeholder="Device Type"
          className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
          onChange={(selectedOption) => {
            if (selectedOption) {
              methods.setValue("type", selectedOption.value);
              setDevice(selectedOption);
            }
          }}
          value={device}
        />
        {methods.formState.errors.type && (
          <span className="text-red-500 text-sm font-semibold mt-1">
            {methods.formState.errors.type.message as string}
          </span>
        )}
      </div>
      <Input name="location" label="Location" placeholder="Enter Location" required />
      {/* <Input name="status" label="Status" placeholder="Enter Status" required/> */}
      <Input name="owner" label="Owner" placeholder="Enter Owner Name" required />
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Assign To</label>
        <Select
          {...methods.register("assign", { required: true })}
          options={data}
          placeholder="Select Employee"
          className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
          onChange={(selectedOption) => {
            if (selectedOption) {
              methods.setValue("assign", selectedOption.label);
              methods.setValue("userEmail", selectedOption.value);
              setSelectedUser(selectedOption);
            }
          }}
          value={selectedUser}
        />
        {methods.formState.errors.assign && (
          <span className="text-red-500 text-sm font-semibold mt-1">{methods.formState.errors.assign.message}</span>
        )}
      </div>
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
        <Select
          {...methods.register("status", { required: true })}
          options={[
            { value: "Working", label: "Working" },
            { value: "Not Working", label: "Not Working" },
          ]}
          placeholder="Select Status"
          className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
          onChange={(selectedOption) => {
            if (selectedOption) {
              methods.setValue("status", selectedOption.value);
              setOption(selectedOption.value);
              setStatus(selectedOption);
            }
          }}
          value={status}
        />
        {methods.formState.errors.status && (
          <span className="text-red-500 text-sm font-semibold mt-1">{methods.formState.errors.status.message}</span>
        )}
      </div>
      <Input name="assignDate" type="date" label="Assign Date" placeholder="Enter Assign Date" required />
      <Input name="identity" type="text" label="Inventory Identity Number" placeholder="Enter Identity Number" />
    </div>,
    <div key="step2" className="grid gap-4 grid-cols-3">
      <Input name="assetPassword" label="Asset Password" placeholder="Enter Asset password" required />
      <Input name="userEmail" label="User Email" type="email" placeholder="Enter User Email" disabled />
      <Input name="purchaseDate" type="date" label="Purchase Date" placeholder="Enter Purchase Date" required />
      <Input name="releaseDate" type="date" label="Release date" placeholder="Enter Release date" required />
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Asset Type</label>
        <Select
          {...methods.register("asset_type", { required: true })}
          options={[
            { value: "Own", label: "Own" },
            { value: "Rental", label: "Rental" },
            { value: "Sample", label: "Sample" },
          ]}
          placeholder="Asset Type"
          className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
          onChange={(selectedOption) => {
            if (selectedOption) {
              methods.setValue("asset_type", selectedOption.value);
              setOption(selectedOption.value);
              setAssetType(selectedOption);
            }
          }}
          value={assetType}
        />
        {methods.formState.errors.asset_type && (
          <span className="text-red-500 text-sm font-semibold mt-1">{methods.formState.errors.asset_type.message}</span>
        )}
      </div>
      {option === "Sample" && (
        <>
          <Input name="issueDate" type="date" label="Issue Date" required />
          <Input name="returnDate" type="date" label="Return Date" required />
        </>
      )}
      {option === "Own" && (
        <>
          <Input name="cost" type="number" label="Cost" placeholder="Eg: 5000" required />
          <Input name="depreciationRate" type="number" label="Depreciation Rate" placeholder="Eg: 10.0" required />
        </>
      )}
      {option === "Rental" && (
        <>
          <Input name="rentAmount" type="number" label="Rental Amount" placeholder="Eg: 5000" required />
          <Input name="rentStartDate" type="date" label="Rent Start Date" required />
        </>
      )}
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Images</label>
        <input
          type="file"
          accept="image/jpeg,image/jpg,image/png,image/bmp,image/tiff"
          multiple
          onChange={(e) => handleImageUpload(e.target.files)}
          className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
        {errorMessage && <span className="text-red-500 text-sm mt-1">{errorMessage}</span>}
      </div>

      <div className="flex space-x-4 justify-center">
        {images &&
          images.map((image: any, index: number) => (
            <div key={index} className="relative">
              <img
                src={URL.createObjectURL(image)}
                alt={`preview-${index}`}
                className="w-24 h-24 object-cover rounded-md"
                loading="lazy"
              />
              <AiOutlineCloseSquare
                onClick={() => removeImage(index)}
                className="cursor-pointer absolute top-0 right-0  text-black rounded-full w-6 h-6 flex items-center justify-center"
              />
            </div>
          ))}
      </div>
      <div className="flex justify-center">
        {!isEmpty(methods.formState.errors) && (
          <span className="text-red-500 text-sm mt-1">Please Check Mandatory Feilds </span>
        )}
      </div>
    </div>,
  ];

  const onSubmit: SubmitHandler<any> = (data: any) => {
    console.log(data);
    const DeviceType = data.asset_type;
    const updatedData = { ...data };

    if (DeviceType === "Own") {
      updatedData.ownedDevice = {
        cost: data.cost,
        depreciationRate: data.depreciationRate,
      };
    } else if (DeviceType === "Rental") {
      updatedData.rentedDevice = {
        rentStartDate: data.rentStartDate,
        rentAmount: data.rentAmount,
      };
    } else if (DeviceType === "Sample") {
      updatedData.sampleDevice = {
        issueDate: data.issueDate,
        returnDate: data.returnDate,
      };
    }
    // console.log("updatedData :>>", updatedData);
    const formData = new FormData();
    const dto_object = new Blob([JSON.stringify(updatedData)], {
      type: "application/json",
    });
    formData.append("asset", dto_object);
    console.log("images", images);
    images.length > 0 &&
      images.forEach((image) => {
        formData.append(`files`, image);
      });
    console.log("formData2", formData);

    apiService
      .post("api/assets/asset", formData)
      .then((res) => {
        console.log("res :>> ", res);
        onClose();
        setRefresh(refresh + 1);
        addToast("Inventory Created Successfully", "success");
      })
      .catch((err) => {
        console.log("err :>> ", err);
        addToast("Something Went Wrong", "error");
      });
  };

  useEffect(() => {
    apiService
      .get("api/users")
      .then((res) => {
        const getEmailOptions = (emailArray: any[]) => {
          return emailArray.map((email: any) => ({
            value: email.email,
            label: email.displayName,
          }));
        };
        const emailOptions = getEmailOptions(res.data);
        const userOption = sortByKey(emailOptions, "label");
        setData(userOption);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <div className="bg-white w-full rounded-md" style={{ width: 900 }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {steps[step]}
          <div className="flex justify-center	 mt-4">
            {step > 0 && (
              <button
                type="button"
                onClick={prevStep}
                className="bg-gray-500 mr-2 text-white px-4 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring focus:border-gray-300"
              >
                Back
              </button>
            )}
            {step < steps.length - 1 && (
              <button
                type="button"
                onClick={nextStep}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
              >
                Next
              </button>
            )}
            {step === steps.length - 1 && (
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddProduct;
