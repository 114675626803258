import MomentUtil from "./Moment";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const monthFullName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dateFormate = (dateval: string | number | Date) => {
  const date = new Date(dateval);
  return MomentUtil.formatWithLocale(date, "DD-MM-YYYY");
};

export const convert = (originalDateStr: any) => {
  const originalDate = new Date(originalDateStr);
  // Extract year, month, and day
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // January is 0
  const day = originalDate.getDate().toString().padStart(2, "0");

  // Format the date as "yyyy-MM-dd"
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const getYear = () => new Date().getFullYear();

export const getMonthByDate = (val: any) => monthFullName[new Date(val).getMonth()];

// function capitalize(word) {
//   return word.charAt(0).toUpperCase() + word.slice(1);
// }

export const capitalize = (word: string) => {
  if (word && word.length) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase();
  }
  return "";
};
