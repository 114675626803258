/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { BreadCrumb } from "components/BreadCrumb";
import Tooltip from "components/Tooltip";
import Modal from "components/Modal";
import UpdateProfile from "./UpdateProfile";
import GenericToaster from "components/Toaster";
import appConfig from "services/apiConfig";
import UpdateDocs from "./UpdateDocs";
import ViewDocs from "./ViewDocs";
import ProfileCard from "./ProfileCard";
import UserProjects from "./UserProjects";
import Tabs from "components/Tab/Tabs";
import DocumentList from "./DocumentList";

export default function ProfileDetails({ user, setRefresh }: any) {
  const [openModal, setOpenModal] = useState(false);
  const BreadCrumbList = [
    { name: "profile", link: "#" },
    { name: "view", link: "#" },
  ];
  const handleClick = () => {
    setOpenModal(true);
  };
  const [toastData, setToastData] = useState<{ message: string; severity: "info" | "error" } | null>(null);
  const udpateProfileImage = (e: any) => {
    const tokenValue = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    if (user)
      fetch(appConfig.getBaseUrl() + "api/image/" + user?.id, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer " + tokenValue,
        },
      })
        .then((res) => {
          if (res.status === 401) {
            window.localStorage.removeItem("accessToken");
          } else return res.json();
        })
        .then((res) => {
          console.log("User Profile Image : ", res);
          setRefresh("updated");
        })
        .catch((err) => {
          console.log("User Profile Image Not upload : ", err);
        });
  };
  const tabData = [
    {
      label: "My Profile",
      content: <ProfileCard user={user} udpateProfileImage={udpateProfileImage} />,
    },
    {
      label: "My Projects",
      content: <UserProjects user={user} />,
    },

    {
      label: "View Document",
      content: <ViewDocs user={user} />,
    },
    {
      label: "Update Document",
      content: <UpdateDocs />,
    },
  ];
  user.role === 2 &&
    tabData.push({
      label: "Document Form",
      content: <DocumentList />,
    });
  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div>
        <div className="flex justify-end">
          <Tooltip content="Click to Update Profile">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={handleClick}
            >
              <span className="">Update Profile</span>
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="container mx-auto py-8">
        <Tabs tabs={tabData} />
      </div>

      {openModal && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} header="Edit Profile Details">
          <UpdateProfile
            data={user}
            onClose={() => setOpenModal(false)}
            setToastData={setToastData}
            setRefresh={setRefresh}
          />
        </Modal>
      )}

      {toastData && (
        <GenericToaster
          open={true}
          message={toastData.message}
          severity={toastData.severity}
          onClose={() => setToastData(null)}
        />
      )}
    </>
  );
}
