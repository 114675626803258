import Editor from "components/Editor";
import React, { useState } from "react";
import Drawer from "./index";

const DrawerExample: React.FC = () => {
  const [richTextValue, setRichTextValue] = useState("");

  const handleRichTextChange = (value: string) => {
    setRichTextValue(value);
  };
  return (
    <div className="text-center">
      <button
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        type="button"
        data-drawer-target="drawer-right-example"
        data-drawer-show="drawer-right-example"
        data-drawer-placement="right"
        aria-controls="drawer-right-example"
      >
        Show right drawer
      </button>

      <Drawer id="drawer-right-example" label="Right drawer">
        <p>Your drawer content goes here.</p>
      </Drawer>
      <div>
        <Editor value={richTextValue} onChange={handleRichTextChange} />
      </div>
    </div>
  );
};

export default DrawerExample;
