/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { FaUserTie, FaUsers, FaInfoCircle } from "react-icons/fa";

function UserProjects({ user }: any) {
  const { t } = useTranslation();

  return (
    <div className="mt-8">
      <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center dark:text-gray-100">
        {t("profile.projectText")}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {user.userProjectWithAssignedDate &&
          user.userProjectWithAssignedDate.length > 0 &&
          user.userProjectWithAssignedDate.map((project: any) => (
            <div
              key={project.projectId}
              className="bg-white rounded-xl shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl border border-gray-200 dark:bg-gray-800 dark:border-gray-700"
            >
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-3">{project.name}</h3>

                <p className="text-gray-600 dark:text-gray-300 mb-5 break-all	truncate hover:text-clip">
                  <FaInfoCircle className="inline mr-2 text-gray-500  dark:text-gray-400" />
                  {project.projectDetail}
                </p>

                <div className="flex items-start flex-col  text-gray-700 dark:text-gray-300">
                  <div className="flex items-center ">
                    <FaUserTie className="mr-2 text-blue-500" />
                    <span>
                      {t("profile.managerText")}: {project.manager}
                    </span>
                  </div>

                  <div className="flex items-center ">
                    <FaUsers className="mr-2 text-green-500" />
                    <span>
                      {t("profile.teamText")}: {project.teamSize}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default memo(UserProjects);
