/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useState } from "react";
interface AppContextProps {
  isLoggedIn: boolean;
  login: () => void;
  logout: () => void;
  userRole: number;
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any | null>>;
  setUserRole: React.Dispatch<React.SetStateAction<number>>;
  email: string;
  setEmail: any;
}
export const AppContextProvider = createContext({} as AppContextProps);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppContext = ({ children }: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState<number>(1);
  const [user, setUser] = useState();
  const [email, setEmail] = useState("");

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsLoggedIn(false);
  };

  const appContextValue = useMemo(
    () => ({
      login,
      logout,
      isLoggedIn,
      userRole,
      user,
      setUser,
      setUserRole,
      email,
      setEmail,
    }),
    [isLoggedIn, userRole, user, email]
  );
  return <AppContextProvider.Provider value={appContextValue}>{children}</AppContextProvider.Provider>;
};
