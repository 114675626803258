/* eslint-disable  */
import React from "react";
import "./App.css";
import "flowbite/dist/flowbite.css";
import Routers from "./routes/Routers";
import { AppContext } from "./contexts/AppContext";
import { ToasterProvider } from "contexts/toasterContext";

function App() {
  if (process.env.NODE_ENV === "production") {
    if (console) {
      console.log = function () {};
    }
  }
  return (
    <>
      <AppContext>
        <ToasterProvider>
          <Routers />
        </ToasterProvider>
      </AppContext>
    </>
  );
}

export default App;
