import React from "react";
interface ButtonProps {
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  color?: string;
}
export default function Button({
  onClick,
  type = "button",
  disabled = false,
  children,
  className,
  color = "blue",
  ...rest
}: ButtonProps) {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={`w-full text-white bg-${color}-600 hover:bg-${color}-700 focus:ring-4 focus:outline-none focus:ring-${color}-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-${color}-600 dark:hover:bg-${color}-700 dark:focus:ring-${color}-800  ${
          disabled ? "cursor-not-allowed opacity-50" : "hover:bg-${color}-700"
        } ${className}`}
        {...rest}
      >
        {children}
      </button>
    </>
  );
}
