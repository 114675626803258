const monthData = [
  {
    day: 1,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 2,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 3,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 4,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 5,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 6,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 7,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 8,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 9,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 10,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 11,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 12,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 13,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 14,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 15,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 16,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 17,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 18,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 19,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 20,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 21,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 22,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 23,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 24,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 25,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 26,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 27,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 28,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 29,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 30,
    task: "",
    hour: "",
    comment: "",
  },
  {
    day: 31,
    task: "",
    hour: "",
    comment: "",
  },
];

export default monthData;
