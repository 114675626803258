/* eslint-disable @typescript-eslint/no-explicit-any */
import { Column } from "components/DataTable";
import GenericModal from "components/Modal";
import React from "react";
import { ReimbursementResponse } from "services/Reimbursement/reimbursementInterface";
import InfoView from "./InfoView";

interface IProps {
  selectedRow: ReimbursementResponse | undefined;
  columns: Column[];
  onClose: () => void;
}

const ViewReimbursement = ({ selectedRow, onClose }: IProps) => {
  return (
    <div>
      <GenericModal
        isOpen={true}
        onClose={onClose}
        header="Reimbursement History"
        customCss={{
          width: "70%",
        }}
      >
        <InfoView {...selectedRow} />
      </GenericModal>
    </div>
  );
};

export default ViewReimbursement;
