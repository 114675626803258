/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Input from "components/Input";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import apiService from "services/apiService";
import moment from "moment";
import monthData from "./newData";
import { getEpochTime } from "util/epochTime";
import { sortByKey } from "util/sortOptions";

const validationSchema = yup.object({
  projects: yup.string().required("Project required"),
  date: yup.string().required("Date is required"),
  Hours: yup
    .number()
    .typeError("Hours must be a number")
    .positive("Hours must be greater than zero")
    .required("Hours is required")
    .max(15, "Hours must be 15 or less"),
  task: yup.string().required("Task is required"),
});

interface IProps {
  projects: Array<any>;
  selectedProj: any;
  selectedUser: any;
  month: string;
  year: string;
  onClose: () => void;
  wholeData: any;
  setRefresh: any;
  refresh: number;
  selectedDate: any;
}

const AddTimeSheet = ({
  projects,
  selectedProj,
  selectedUser,
  month,
  year,
  onClose,
  wholeData,
  setRefresh,
  refresh,
  selectedDate,
}: IProps) => {
  const [selectedProject, setSelectedProject] = useState<string | null>(selectedProj);
  const [date, setDate] = useState("");
  const [task, setTask] = useState("");
  const [hour, setHour] = useState(0);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    const newdate = moment(selectedDate).format("YYYY-MM-DD");
    setDate(newdate);
    methods.setValue("date", newdate);
  }, []);
  const TempPro: { value: any; label: any }[] =
    projects && projects.length > 0
      ? projects.map((project: any) => ({
          value: project.projectId,
          label: project.name,
        }))
      : [];
  const projectOptions = sortByKey(TempPro, "label");

  const handleProjectChange = (selectedOption: any) => {
    setSelectedProject(selectedOption.value);
    methods.setValue("projects", selectedOption.value);
  };

  useEffect(() => {
    methods.setValue("projects", selectedProj || methods.getValues().projects);
    methods.setValue("date", date || methods.getValues().date);
  }, [methods]);
  function extractDay(dateString: string) {
    const date = new Date(dateString);
    return date.getDate() || Date.now();
  }
  function extractMonth(dateString: string) {
    const date = new Date(dateString);
    return moment(date).format("MMM");
  }
  function extractYear(dateString: string) {
    const date = new Date(dateString);
    return moment(date).format("YYYY");
  }
  // function updateField({ wholeData, selectedProject, newValues }: any) {
  //   console.log("wholeData:>> ", wholeData, selectedProject, newValues);
  //   if (wholeData.data) {
  //     console.log("condition true :>> update feild");
  //     for (const project of wholeData.data) {
  //       if (project.projectId === selectedProject) {
  //         // Filter out the existing entries for the specific day
  //         project.detail = project.detail.filter((detail: any) => detail.day == newValues.day);
  //         // Add the new entry
  //         console.log("project.detail", project.detail);
  //         const ValuesA = { ...newValues, id: project.detail.length > 0 ? project.detail[0].id : "" };
  //         console.log("ValuesA :>> ", ValuesA);
  //         project.detail = [];
  //         project.detail.push(ValuesA);
  //         break;
  //       } else {
  //         console.log("project,wholeData", wholeData);
  //       }
  //     }
  //     return wholeData;
  //   } else {
  //     const wholeData = {
  //       email: selectedUser,
  //       status: "InProgress",
  //       projectIds: `${selectedProject}`,
  //       comment: "",
  //       isNew: true,
  //       data: [
  //         {
  //           projectId: selectedProject,
  //           detail: [newValues],
  //           comment: "",
  //           status: "InProgress",
  //         },
  //       ],
  //     };
  //     return wholeData;
  //   }
  // }
  function updateField({ wholeData, selectedProject, newValues }: any) {
    console.log("wholeData:>> ", wholeData, selectedProject, newValues);
    if (wholeData.data) {
      console.log("condition true :>> update field");
      let projectFound = false;
      for (const project of wholeData.data) {
        if (project.projectId === selectedProject) {
          projectFound = true;
          console.log("first loop :>>>");
          // Filter out the existing entries for the specific day
          project.detail = project.detail.filter((detail: any) => detail.day != newValues.day);
          // Add the new entry
          console.log("project.detail", project.detail);
          const ValuesA = { ...newValues, id: "" };
          console.log("ValuesA :>> ", ValuesA);
          project.detail.push(ValuesA);
          console.log("project", project);
          break;
        }
      }

      if (!projectFound) {
        // If the selected project ID is not found, add a new project with the new values
        console.log("Project not found, adding new project.");
        const newProject = {
          projectId: selectedProject,
          detail: [newValues],
          comment: "",
          status: "InProgress",
          totalHour: newValues.hour,
        };
        wholeData.data.push(newProject);
      }

      return wholeData;
    } else {
      console.log("third condition:>>>>>>>>>>");
      const newWholeData = {
        email: selectedUser,
        status: "InProgress",
        projectIds: `${selectedProject}`,
        comment: "",
        isNew: true,
        data: [
          {
            projectId: selectedProject,
            detail: [newValues],
            comment: "",
            status: "InProgress",
            totalHour: newValues.hour,
          },
        ],
      };
      return newWholeData;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (data: any) => {
    console.log("data", data);

    const newValues = {
      day: extractDay(data.date),
      task: task,
      hour: hour,
      comment: "",
      date: getEpochTime(data.date),
    };
    const value = updateField({ wholeData, selectedProject, newValues });
    value.month = extractMonth(data.date);
    value.year = extractYear(data.date);
    value.projectIds = `${selectedProject}`;
    console.log("value :>>", value);
    if (!value.isNew) {
      console.log("put", value);
      apiService
        .put(`api/timesheet/${value.id}`, value)
        .then((res) => {
          console.log("res", res);
          setRefresh(refresh + 1);
          onClose();
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    } else {
      // apiService
      //   .post(`api/timesheet`, value)
      //   .then((res) => {
      //     console.log("res", res);
      //     setRefresh(refresh + 1);
      //     onClose();
      //   })
      //   .catch((err) => {
      //     console.log("err :>> ", err);
      //   });

      const payloadData: Array<any> = projects.map((project) => {
        const payload: any = {
          projectId: project.projectId,
          projectName: project.name,
          detail: monthData,
          comment: "",
          status: "InProgress",
          totalHour: hour,
        };
        const taskDetails: Array<any> = [];
        // payload.detail = [...taskDetails];
        payload.totalHour = hour;
        return payload;
      });

      const wholeData = {
        email: selectedUser,
        comment: task,
        year: year,
        month: month,
        status: "InProgress",
        totalHour: hour,
        submitDate: new Date(),
        projectIds: selectedProject,
        userName: "",
        data: [...payloadData],
      };
      console.log("post :>", wholeData);
      const newValue1 = updateField({ wholeData, selectedProject, newValues });
      console.log("newValue1  post new ==>", newValue1);
      apiService
        .post(`api/timesheet`, value)
        .then((res) => {
          if (res.status === 200) {
            setRefresh(refresh + 1);
            onClose();
            console.log("res created:>> ", res);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleTaskChange = (e: any) => {
    setTask(e.target.value);
  };

  const handleDateChange = (e: any) => {
    setDate(e.target.value);
    methods.setValue("date", e.target.value);
  };

  const handleHoursChange = (e: any) => {
    setHour(e.target.value);
  };

  return (
    <div className="space-y-6 md:grid-cols-2">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid gap-6 mb-1 md:grid-cols-2">
            <div className="form-group mb-1">
              <label htmlFor="project" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Select Project
              </label>

              <Select
                name="projects"
                options={projectOptions}
                value={projectOptions.find((option) => option.value === selectedProject)}
                className="select"
                onChange={handleProjectChange}
              />

              {methods.formState.errors.projects && (
                <span className="text-red-500 text-sm mt-1">{methods.formState.errors.projects.message}</span>
              )}
            </div>
            <div className="grid gap-6 mb-1 md:grid-cols-2">
              <div>
                <Input type="date" label="Date" name="Date" onChange={handleDateChange} value={date} />
              </div>
              <div>
                <Input
                  name="Hours"
                  label="Hours"
                  type="number"
                  placeholder="Eg: 8"
                  onChange={handleHoursChange}
                  value={hour}
                />
              </div>
            </div>
          </div>
          <Input
            type="textarea"
            label="Task"
            name="task"
            placeholder="Worked on Sample task"
            onChange={handleTaskChange}
            value={task}
            required
          />
          <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
            <button
              type="button"
              onClick={onClose}
              className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
            >
              Cancel
            </button>

            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 ms-3"
            >
              Add
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddTimeSheet;
