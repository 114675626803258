/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import RentedList from "./RentedList";
import { FaEdit, FaEye } from "react-icons/fa";
import Modal from "components/Modal";
import ViewProduct from "./ViewProduct";
import AddProduct from "./AddProduct";
import EditInventory from "./EditInventory";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import apiService from "services/apiService";

export default function SampleInventory() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rentedData, setInvetories] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [productData, setProductData] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleView = (row: any) => {
    console.log("row", row);
    setProductData(row);
    setOpenModal(true);
  };
  type Row = {
    id: number;
    assign: string;
    type: string;
    status: string;
    owner: string;
    location: string;
    model: string;
    brand: string;
    assignDate: string;
  };
  const tableColumns = [
    {
      key: "issuedTo",
      label: "Issued To",
      bold: true,
    },
    {
      key: "issueDate",
      label: "Issue Date",
      type: "Date",
    },
    {
      key: "returnDate",
      label: "Return Date",
      type: "Date",
    },

    {
      key: "actions",
      label: `${t("inventories.actions")}`,
      excludeExport: true,
      actions: (row: object) => (
        <div className="flex flex-start">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={() => handleEdit(row)}
          >
            <FaEdit className="text-white" style={{ marginRight: 5 }} />
            Edit
          </button>
          <button
            type="button"
            className="text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center me-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
            onClick={() => handleView(row)}
          >
            <FaEye className="text-white" style={{ marginRight: 5 }} />
            View
          </button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    setLoading(true);
    apiService
      .get("api/devices/rentedDevice/all")
      .then((res) => {
        console.log("res.data.assets :>> ", res.data.assets);
        const invetories: Row[] = res?.data.assets.map((item: Row[]) => ({
          ...item,
        }));
        console.log("invetoriesResponses ", invetories);
        setInvetories(res?.data.assets);
      })
      .catch((error) => {
        console.error("Error fetching Reimbursement list:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const [addProduct, setAddPrdouct] = useState(false);
  const handleAddfunction = () => {
    setAddPrdouct(!addProduct);
  };

  const [editProduct, setEditPrdouct] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEdit = (row: any): void => {
    console.log("row", row);
    setProductData(row);
    setEditPrdouct(true);
  };
  const BreadCrumbList = [
    { name: "Sample Inventory", link: "#" },
    { name: "list", link: "#" },
  ];

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <RentedList data={rentedData} columns={tableColumns} onAdd={handleAddfunction} loading={loading} />
      {openModal && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} header="View Sample Inventory">
          {productData && <ViewProduct product={productData} />}
        </Modal>
      )}

      {addProduct && (
        <Modal
          isOpen={addProduct}
          onClose={() => setAddPrdouct(false)}
          header="Add Sample Inventory"
          customCss={{
            width: "900px !important",
          }}
        >
          {addProduct && <AddProduct onClose={() => setAddPrdouct(false)} />}
        </Modal>
      )}

      {editProduct && (
        <Modal
          isOpen={editProduct}
          onClose={() => setEditPrdouct(false)}
          header="Edit Sample Inventory"
          customCss={{
            width: "900px !important",
          }}
        >
          {editProduct && <EditInventory onClose={() => setEditPrdouct(false)} data={productData} />}
        </Modal>
      )}
    </>
  );
}
