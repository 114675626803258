/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import DataTable, { Row } from "components/DataTable";
import apiService from "services/apiService";
import { FaEdit, FaEye } from "react-icons/fa";
import Modal from "components/Modal";
import EmployeeDetails from "./EmployeeDetails";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import GenericToaster from "components/Toaster";

export default function EmployeeList() {
  const [data, setData] = useState<Row[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [viewData, setViewData] = useState();
  const [addEmployee, setAddEmployee] = useState(false);
  const [editEmployee, setEditEmployee] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [userActive, setUserActive] = useState("users");
  const [showSuccessToaster, setSuccessToaster] = useState(false);
  const [showErrorToaster, setErrorToaster] = useState(false);
  const [message, setMessage] = useState("");

  const { t } = useTranslation();
  useEffect(() => {
    setLoading(true);
    apiService
      .get(`api/${userActive}`)
      .then((res) => {
        const newData = res.data.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName));
        setData(newData);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh, userActive]);
  const tableColumns = [
    // { key: "id", label: `Id`, bold: true },
    { key: "displayName", label: `${t("employees.name")}`, bold: true },
    { key: "email", label: `${t("employees.email")}` },
    {
      key: "mobileNumber",
      label: `${t("employees.mobileNumber")}`,
      bold: true,
    },
    {
      key: "userProjectWithAssignedDate",
      label: `Projects`,
      excludeExport: true,
      // eslint-disable-next-line
      render: (row: any) => (
        <>
          {row ? (
            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
              {row.length ? row.length : 0}
            </span>
          ) : (
            <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
              0
            </span>
          )}
        </>
      ),
    },
    {
      key: "role",
      label: `${t("employees.role")}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any) => {
        if (row === 2) {
          return (
            <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
              {t("employees.admin")}
            </span>
          );
        } else {
          return (
            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
              {t("employees.user")}
            </span>
          );
        }
      },
    },
    {
      key: "userStatus",
      label: `${t("employees.userStatus")}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any) => {
        if (row === "ACTIVE") {
          return (
            <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
              {row}
            </span>
          );
        } else {
          return (
            <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
              {row}
            </span>
          );
        }
      },
    },
    {
      key: "actions",
      label: `${t("employees.actions")}`,
      excludeExport: true,
      actions: (row: object) => (
        <div className="flex flex-start">
          <span onClick={() => handleEdit(row)} className="btn-primary px-2 py-2 mr-2 rounded-lg">
            <FaEdit className="text-white" />
          </span>
          <span onClick={() => handleView(row)} className="btn-primary px-2 py-2 mr-2 rounded-lg">
            <FaEye className="text-white" />
          </span>
        </div>
      ),
    },
  ];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleView = (row: any): void => {
    console.log("row", row);
    setViewData(row);
    setOpenModal(true);
  };
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleEdit = (row: any): void => {
    setViewData(row);
    setEditEmployee(true);
  };
  // const handleAddfunction = () => {
  //   setAddEmployee(!addEmployee);
  // };

  const BreadCrumbList = [
    { name: "Employees", link: "#" },
    { name: "List", link: "#" },
  ];
  const handleEditEmployeeClose = () => {
    setEditEmployee(false);
    setRefresh((prevKey) => prevKey + 1);
  };
  const addEditEmployeeClose = () => {
    setAddEmployee(false);
    setRefresh((prevKey) => prevKey + 1);
  };

  const handleSuccess = (message: string) => {
    setMessage(message);
    setSuccessToaster(true);
    
  };

  const handleError = (message: string) => {
    setMessage(message);
    setErrorToaster(true);
  };

  const closeToaster = () => {
    setErrorToaster(false);
    setSuccessToaster(false);
  };


  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div className="flex space-x-4">
        <button
          className={`py-2 rounded-lg px-8 border  ${userActive === "users" ? "bg-blue-700 text-white" : "bg-white"}`}
          onClick={() => setUserActive("users")}
        >
          {/* Active Employees */}
          {`${t("Active")} ${t("Employees")}`}
        </button>
        <button
          className={`py-2 rounded-lg px-8 border  ${userActive === "inactive-users" ? "bg-blue-700 text-white" : "bg-white"}`}
          onClick={() => setUserActive("inactive-users")}
        >
          {/* Inactive Employees */}
          {`${t("Inactive")} ${t("Employees")}`}
        </button>
      </div>
      {data && (
        <DataTable
          data={data}
          columns={tableColumns}
          loading={loading}
          // title={userActive === "users" ? "Active Employees List" : "Inactive Employees List"}
         
          title={userActive === "users" ? `${t("Active")} ${t("Employees")} ${t("List")}` : `${t("Inactive")} ${t("Employees")} ${t("List")}`}
         

          //  onAddBtnClick={handleAddfunction}
        />
      )}
      {openModal && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} header="View Employee Details">
          {viewData && <EmployeeDetails data={viewData} />}
        </Modal>
      )}
      {addEmployee && (
        <Modal isOpen={addEmployee} onClose={addEditEmployeeClose} header="Add Employee">
          <AddEmployee onClose={addEditEmployeeClose} />
        </Modal>
      )}
      {editEmployee && (
        <Modal isOpen={editEmployee} onClose={handleEditEmployeeClose} header="Edit Employee">
           <EditEmployee onClose={handleEditEmployeeClose} data={viewData} onSuccess={handleSuccess} onError={handleError} />

</Modal>
)}

{(showSuccessToaster || showErrorToaster) && (
  <GenericToaster
    message={message}
    onClose={closeToaster}
    open={showSuccessToaster || showErrorToaster}
    severity={showErrorToaster ? "error" : "info"}
  />
)}

    </>
  );
}
