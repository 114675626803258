/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { dateFormate } from "util/Util";

interface IProps {
  selectedRow: any;
}

const ViewLeave = ({ selectedRow }: IProps) => {

 
  return (
    <div className="space-y-6 md:grid-cols-2">
      <div className="form-group grid grid-rows-5 gap-6">
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex flex-col">
            <label htmlFor="project" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Leave From
            </label>

            <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
              {dateFormate(selectedRow.leaveFrom) || 0}
            </p>
          </div>
          <div className="flex flex-col">
            <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Leave To
            </label>
            <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
              {dateFormate(selectedRow.leaveTo) || 0}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex flex-col">
            <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Days
            </label>
            <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
              {selectedRow.unit || 0}
            </p>
          </div>
          <div className="flex flex-col">
            <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Applied Date
            </label>
            <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
              {selectedRow.leaveAppliedDate ? dateFormate(selectedRow.leaveAppliedDate) || 0 : "-"}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div className="flex flex-col">
            <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Year
            </label>
            <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
              {selectedRow.year || 0}
            </p>
          </div>
          <div className="flex flex-col">
            <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Month
            </label>
            <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
              {selectedRow.month || 0}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div className="flex flex-col">
            <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Status
            </label>
            <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
              {selectedRow.status || "\u00a0\u00a0"}
            </p>
          </div>
    {/* Adding logics , if Field is empty it does not show fields During ViewLeave model */}
         { selectedRow?.reason?(
          
          <div className="flex flex-col">
            <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Reason <span className="text-red-500"> *</span>
            </label>
            <p className="bg-gray-50 truncate  border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
              {selectedRow?.reason || "\u00a0\u00a0"}
            </p>
          </div>):null
       }
        
          
        </div>
        {/*  Adding logics , if Field is empty it does not show fields During  ViewLeave model*/}
        {selectedRow.comment?(
          <div className="grid grid-cols-1">
          <div className="flex flex-col">
            <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Comment
            </label>
            <p className="bg-gray-50 truncate  border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
              {selectedRow?.comment || "\u00a0\u00a0"}
            </p>
          </div>
        </div>):null}
        
      </div>
    </div>
  );
};

export default ViewLeave;
