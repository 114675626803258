/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosResponse } from "axios";
import appConfig from "./apiConfig";
import { storageService } from "./storageService";

class ApiService {
  private axiosClient: AxiosInstance;
  constructor() {
    this.axiosClient = axios.create({
      baseURL: appConfig.getBaseUrl(),
    });
    this.axiosClient.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("accessToken");
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          storageService.removeItem("accessToken");
          window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );
  }

  public async get(url: string): Promise<AxiosResponse> {
    // console.log("url :>> ", url);
    const response = await this.axiosClient.get(url);
    return response;
  }

  public async post(url: string, data: any): Promise<AxiosResponse> {
    const response: AxiosResponse = await this.axiosClient.post(url, data);
    return response;
  }

  public async put(url: string, data: any): Promise<AxiosResponse> {
    const response = await this.axiosClient.put(url, data);
    return response;
  }

  public async patch(url: string, data: any): Promise<AxiosResponse> {
    const response = await this.axiosClient.patch(url, data);
    return response;
  }

  public async delete(url: string, data?: any): Promise<AxiosResponse> {
    const response = await this.axiosClient.delete(url, data);
    return response;
  }
}

const apiService = new ApiService();

export default apiService;
