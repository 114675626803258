import { AxiosResponse } from "axios";
import { ReimbursementHistoryResponse, ReimbursementResponse } from "./reimbursementInterface";
import apiService from "services/apiService";

class ReimbursementManager {
    private url: string;
    constructor() {
        this.url = `api/reimbursement`;
    }

    async getAllReimbursementData(): Promise<AxiosResponse<Array<ReimbursementResponse>>> {
        return await apiService.get(this.url);
    }

    async getReimbursementHistory(id: number): Promise<AxiosResponse<Array<ReimbursementHistoryResponse>>> {
        return await apiService.get(`${this.url}/history/${id}`);
    }

    async addReimbursement(formData: FormData): Promise<AxiosResponse> {
        return await apiService.post(this.url, formData)
    }

    async updateReimbursement(formData: FormData): Promise<AxiosResponse> {
        return await apiService.put(this.url, formData);
    }

    async deleteReimbursement(id: number): Promise<AxiosResponse> {
        return await apiService.delete(`${this.url}/${id}`);
    }
}

const reimbursementManager = new ReimbursementManager();
export default reimbursementManager;