import { LanguageSwitcher } from "pages/languageswitcher/languageSwitcher";
import React, { memo } from "react";
// import { FaSun, FaMoon } from "react-icons/fa";

const ThemeSwitcher = () => {
  // const [isDarkMode, setIsDarkMode] = useState(false);

  // useEffect(() => {
  //   document.body.classList.toggle("dark", isDarkMode);
  // }, [isDarkMode]);

  // const toggleTheme = () => {
  //   setIsDarkMode((prev) => !prev);
  // };

  return (
    <>
      <LanguageSwitcher />
      <div className="flex items-center space-x-2 mr-2">
        {/* <button
        type="button"
        className="text-gray-400  hover:bg-gray-400 hover:text-whitefont-medium rounded-full text-sm p-2 text-center inline-flex items-center dark:border-gray-500 dark:text-gray-500 dark:hover:text-white "
        onClick={toggleTheme}
      >
        {isDarkMode ? <FaSun size={20} color="#FFD700" /> : <FaMoon size={20} color="#333" />}
      </button> */}
      </div>
    </>
  );
};

export default memo(ThemeSwitcher);
