import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAppContext } from "contexts";

export default function ProtectedRoutes() {
  const { userRole } = useAppContext();
  if (userRole === 2) {
    return <Outlet />;
  } else {
    return <Navigate to="/admin/profile" />;
  }
}
