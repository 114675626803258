import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from "react";
import { FiCheckCircle, FiInfo, FiAlertCircle } from "react-icons/fi"; // Importing icons for the toasts

type Toast = {
  id: string;
  message: string;
  type: "success" | "error" | "info";
};

type ToasterContextType = {
  addToast: (message: string, type: Toast["type"]) => void;
};

const ToasterContext = createContext<ToasterContextType | undefined>(undefined);

export const ToasterProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback((message: string, type: Toast["type"]) => {
    const id = Math.random().toString(36).substr(2, 9);
    setToasts((currentToasts) => [...currentToasts, { id, message, type }]);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setToasts((currentToasts) => currentToasts.slice(1));
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ToasterContext.Provider value={{ addToast }}>
      {children}
      {/* Toast Container */}
      <div className="fixed top-5 right-5 space-y-4 z-50">
        {toasts.map((toast) => (
          <div
            key={toast.id}
            className={`flex items-center space-x-3 p-4 rounded-lg shadow-lg text-white animate-slideIn transition-transform transform ease-in-out duration-300
              ${toast.type === "success" ? "bg-green-500" : toast.type === "error" ? "bg-red-500" : "bg-blue-500"}`}
          >
            {/* Icons based on toast type */}
            {toast.type === "success" && <FiCheckCircle className="text-xl" />}
            {toast.type === "error" && <FiAlertCircle className="text-xl" />}
            {toast.type === "info" && <FiInfo className="text-xl" />}

            {/* Message */}
            <span className="font-semibold">{toast.message}</span>
          </div>
        ))}
      </div>
    </ToasterContext.Provider>
  );
};

export const useToaster = (): ToasterContextType => {
  const context = useContext(ToasterContext);
  if (!context) {
    throw new Error("useToaster must be used within a ToasterProvider");
  }
  return context;
};
