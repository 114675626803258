/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";

const getEpochTime = (date: Date) => {
  const epochTime = moment(date).unix();
  return epochTime;
};
const getUnixTime = (date: any) => {
  const dateInMilliseconds = date > 1e10 ? date : date * 1000;
  const date1 = new Date(dateInMilliseconds);
  const formattedDate = moment(date1).format("DD-MM-YYYY");
  return formattedDate;
};
const getFormatDate = (date: any) => {
  const formattedDate = moment(date).format("DD-MM-YYYY");
  return formattedDate;
};

export { getEpochTime, getUnixTime, getFormatDate };
