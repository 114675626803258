import React from "react";

interface BadgeProps {
  children: React.ReactNode;
  className?: string;
}
export default function Badge({ children, className, ...rest }: BadgeProps) {
  return (
    <span
      className={`bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400 
      ${className ? className : ""}
      `}
      {...rest}
    >
      {children}
    </span>
  );
}
