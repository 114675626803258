import React from "react";
import { BreadCrumbListProps } from "./breadcrumb.type";
import { useTranslation } from "react-i18next";
/*

 const BreadCrumbList = [
    { name: "Reimbursement", link: "#" },
    { name: "Reimbursement", link: "#s" },
  ];*/
export const BreadCrumb = ({ BreadCrumbList }: BreadCrumbListProps) => {
  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const {t} = useTranslation()
  // function t(arg0: string): React.ReactNode | Iterable<React.ReactNode> {
  //   throw new Error("Function not implemented.");
  // }

  return (
    <>
      <nav className="flex mb-5" aria-label="Breadcrumbb">
        <ol className="inline-flex items-center space-x-1 text-sm font-medium md:space-x-2">
          <li className="inline-flex items-center">
            <a
              href="#"
              className="inline-flex items-center text-gray-700 hover:text-primary-600 dark:text-gray-300 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5 mr-2.5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              {t("Home")}
            </a>
          </li>
          {BreadCrumbList.map((item, index) => {
            return (
              <li key={index}>
                <div className="flex items-center">
                  <svg
                    className="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  {index === BreadCrumbList.length - 1 ? (
                    <span className="ml-1 text-gray-400 md:ml-2 dark:text-gray-500" aria-current="page">
                      {t(capitalize(item.name))}
                    </span>
                  ) : (
                    <a
                      href={item.link ? item.link : "#"}
                      className="ml-1 text-gray-700 hover:text-primary-600 md:ml-2 dark:text-gray-300 dark:hover:text-white"
                    >
                      {t(capitalize(item.name))}
                    </a>
                  )}
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    </>
  );
};
