/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import DataTable, { Row } from "components/DataTable";
import apiService from "services/apiService";
import { FaEdit, FaEye } from "react-icons/fa";
import Modal from "components/Modal";
import ProjectDetail from "./ProjectDetail";
import AddProject from "./AddProject";
import EditProject from "./EditProject";
import { BreadCrumb } from "components/BreadCrumb";
import GenericToaster from "components/Toaster";
import { useTranslation } from "react-i18next";

export default function EmployeeList() {
  const [data, setData] = useState<Row[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [viewData, setViewData] = useState();
  const [addproject, setAddProject] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showSuccessToaster, setSuccessToaster] = useState(false);
  const [showErrorToaster, setErrorToaster] = useState(false);
  const [message, setMessage] = useState("Something went wrong");

  const {t} = useTranslation();

  useEffect(() => {
    getProjectList();
  }, []);

  const getProjectList = () => {
    setLoading(true);
    apiService
      .get("api/project")
      .then((res) => {
        console.log("res :>> ", res);
        if (res.status === 200) {
          // const data = res.data.sort((a: any, b: any) => b.projectId - a.projectId);
          const newData = res.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
          setData(newData);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const tableColumns = [
    // { key: "id", label: "Id", bold: true },
    { key: "name", label: "Project Name", bold: true },
    { key: "manager", label: "Manager", bold: true },
    { key: "teamSize", label: "Team Size", bold: true },
    { key: "clientContactName", label: "Client Name", bold: true },
    { key: "projectDetail", label: "Project Detail" },
    {
      key: "actions",
      label: "Actions",
      excludeExport: true,
      actions: (row: object) => (
        <div className="flex flex-start">
          <span onClick={() => handleEdit(row)} className="btn-primary px-2 py-2 mr-2 rounded-lg">
            <FaEdit className="text-white" />
          </span>
          <span onClick={() => handleView(row)} className="btn-primary px-2 py-2 mr-2 rounded-lg">
            <FaEye className="text-white" />
          </span>
        </div>
      ),
    },
  ];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleView = (row: any): void => {
    console.log("row", row);
    setViewData(row);
    setOpenModal(true);
  };
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleEdit = (row: any): void => {
    setViewData(row);
    setEdit(true);
  };
  const handleAddfunction = () => {
    setAddProject(!addproject);
  };

  const BreadCrumbList = [
    { name: "Projects", link: "#" },
    { name: "List", link: "#" },
  ];

  const handleSuccess = (message: string) => {
    setMessage(message);
    setSuccessToaster(true);
    getProjectList();
  };

  const handleError = (message: string) => {
    setMessage(message);
    setErrorToaster(true);
  };

  const closeToaster = () => {
    setErrorToaster(false);
    setSuccessToaster(false);
  };

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <DataTable
        data={data}
        columns={tableColumns}
        loading={loading}
        onAddBtnClick={handleAddfunction}
        title={`${t("Projects")} ${t("List")}`}
      />
      {openModal && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} header="View Project Details">
          {viewData && <ProjectDetail data={viewData} />}
        </Modal>
      )}
      {addproject && (
        <Modal isOpen={addproject} onClose={() => setAddProject(false)} header="Add Project">
          <AddProject onClose={() => setAddProject(false)} onSuccess={handleSuccess} onError={handleError} />
        </Modal>
      )}
      {edit && (
        <Modal isOpen={edit} onClose={() => setEdit(false)} header="Edit Project">
          <EditProject onClose={() => setEdit(false)} data={viewData} onSuccess={handleSuccess} onError={handleError} />
        </Modal>
      )}
      {(showSuccessToaster || showErrorToaster) && (
        <GenericToaster
          message={message}
          onClose={closeToaster}
          open={showSuccessToaster || showErrorToaster}
          severity={showErrorToaster ? "error" : "info"}
        />
      )}
    </>
  );
}
