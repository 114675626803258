import React, { useState } from "react";

interface Image {
  id: string;
  url: string;
  objectKey: string;
}

interface ImageListProps {
  images: Image[];
}

const ImageList: React.FC<ImageListProps> = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<string | null>(null);

  const openLightbox = (imageUrl: string) => {
    setCurrentImage(imageUrl);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
    setCurrentImage(null);
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3 mb-4">
        {images.map((image) => (
          <div
            key={image.id}
            className="relative overflow-hidden rounded-lg cursor-pointer"
            onClick={() => openLightbox(image.url)}
          >
            <img
              src={image.url}
              alt={image.objectKey}
              className="object-cover w-full h-48 sm:h-56 md:h-64 lg:h-72"
              loading="lazy"
              style={{ minWidth: 300 }}
            />
          </div>
        ))}
      </div>

      {isOpen && currentImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          style={{ backgroundColor: "#9ca3af" }}
        >
          <div className="relative">
            <button
              className="absolute top-0 right-0 m-4 text-white text-3xl text-gray-400 bg-gray-700  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={closeLightbox}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
            <img
              className="w-full object-contain"
              src={currentImage}
              alt="Something went wrong!"
              loading="lazy"
              style={{ maxHeight: 500 }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageList;
