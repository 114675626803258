import { AxiosResponse } from "axios";
import apiService from "services/apiService";
import { AddHolidayPayload, HolidayResponse } from "./holidayInterface";

class HolidayManager {
    private url: string;
    constructor() {
        this.url = 'api/holiday';
    }

    async getHolidayList(year: number): Promise<AxiosResponse<Array<HolidayResponse>>> {
        return await apiService.get(`${this.url}/${year}`);
    }

    async addHoliday(payload: AddHolidayPayload): Promise<AxiosResponse> {
        return await apiService.post(this.url, payload);
    }

    async uploadHolidayCSV(payload: FormData): Promise<AxiosResponse> {
        return await apiService.post(`${this.url}/uploadHolidayCsv`, payload);
    }
}

const holidayManager = new HolidayManager();

export default holidayManager;