/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { dateFormate } from "util/Util";

const ProjectDetail = ({ data }: any) => {
  const project = data;
  return (
    //  Here Changed Text And Font Size For Every Field
    <div className=" rounded-xl overflow-hidden shadow-md">
      <div className="md:flex">
        <div className="p-4">
          <p className="mt-2 text-gray-500 space-x-2">
           <span className="font-semibold text-lg">Project Id : </span><span className="text-gray-800">{project.projectId}</span> 
          </p>
          {/* <div className="flex space-x-3 mt-2  leading-tight font-medium text-black"> */}
          <p className="mt-2 text-gray-500 space-x-2">
            <span className="font-semibold text-lg"> Project Name : </span> <span className="text-gray-800">{project.name || "unnamed Project"}</span>
           </p>
          {/* </div> */}
          <p className="mt-2 text-gray-500 space-x-2">
            <span className="font-semibold text-lg">Manager :</span><span className="text-gray-800"> {project.manager || "Not specified"}</span>
          </p>
          <p className="mt-2 text-gray-500 space-x-2">
            <span className="font-semibold text-lg">Team Size:</span> <span  className="text-gray-800">{project.teamSize || "Not specified"}</span>
          </p>
          <p className="mt-2 text-gray-500 space-x-2">
            <span className="font-semibold text-lg">Start Date:</span><span className="text-gray-800">{dateFormate(project.startDate) || "Not specified"}</span> 
          </p>
          <p className="mt-2 text-gray-500 space-x-2">
            <span className="font-semibold text-lg">Completion Date:</span>{" "}
             <span className="text-gray-700">{dateFormate(project.completionDate) || "Not specified"}</span>
          </p>
          <p className="mt-2 text-gray-500 space-x-2">
            <span className="font-semibold text-lg">Client Name : </span><span className="text-gray-800"> {project.clientContactName || "Not specified"}</span> 
            
          </p>
          <p className="mt-2 text-gray-500 space-x-2">
            <span className="font-semibold text-lg">Contact : </span> <span className="text-gray-800"> {project.clientContactNumber && ` ${project.clientContactNumber}`}</span> 
           
          </p>
          <p className="mt-2 text-gray-500 space-x-2">
            <span className="font-semibold text-lg ">Project Detail : </span><span className="text-gray-900"> {project.projectDetail || "Not specified"}</span>
          </p>
          <div className="mt-4">
            <span className="font-semibold text-lg ">Emails:</span>{" "}
            {project.emails &&
              project.emails.split(",").map((email: string, index: number) => (
                <span key={index} className="text-blue-500">
                  {email.trim()}
                  {index < project.emails.split(",").length - 1 && ", "}
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
