const inventorytypes = {
  IPhone: "IPhone",
  Android: "Android",
  Laptop: "Laptop",
  SIM: "SIM",
  Monitor: "Monitor",
  CPU: "CPU",
  Mouse: "Mouse",
  KeyBoard: "KeyBoard",
  Headphone: "Headphone",
  Charger: "Charger",
  USB: "USB",
  UPS: "UPS",
  Tablet: "Tablet",
  Smart_Watch: "Smart Watch",
};

export const inventoryOptions = Object.entries(inventorytypes).map(([key, value]) => ({
  value: key,
  label: value,
}));
