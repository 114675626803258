class StorageService {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem(key: string, value: any) {
    this.storage.setItem(key, value);
  }

  getItem(key: string) {
    const storedItem = this.storage.getItem(key);
    return storedItem ? storedItem : null;
  }

  removeItem(key: string) {
    this.storage.removeItem(key);
  }
}

export const storageService = new StorageService(localStorage);
