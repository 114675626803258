import React from "react";

export default function NotFound() {
  return (
    <>
      <div className="max-w-[50rem] flex flex-col mx-auto w-full h-full center">
        <div className="text-center py-10 px-4 sm:px-6 lg:px-8">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
            404
          </h1>
          <h1 className="block text-2xl font-bold text-white"></h1>
          <p className="mt-3 text-gray-600 dark:text-gray-400">Oops, something went wrong.</p>
          <p className="text-gray-600 dark:text-gray-400">Sorry, we couldnt find your page.</p>
        </div>
      </div>
    </>
  );
}
