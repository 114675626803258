import React from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/Input";
import { InventoryPayload } from "services/Inventory/inventoryInterface";

const schema = yup.object().shape({
  issuedTo: yup.string().email().required("Email is required"),
  issueDate: yup.string().required("Issue Date is required"),
  returnDate: yup.string().required("Return Date is required"),
});
interface AddProductProps {
  onClose: () => void;
}
const AddProduct = ({ onClose }: AddProductProps) => {
  const methods = useForm({ resolver: yupResolver(schema) });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit: SubmitHandler<any> = (data: InventoryPayload) => {
    console.log(data);
    // inventoryManager
    //   .addInventoryData(data)
    //   .then((res) => {
    //     console.log("res :>> ", res);
    onClose();
    //   })
    //   .catch((err) => {
    //     console.log("err :>> ", err);
    //   });
  };

  return (
    <div className="bg-white  w-full  rounded-md" style={{ width: 400 }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid gap-4 grid-cols-1">
            <Input name="issuedTo" type="email" label="Issued To" placeholder={`Eg : vijay@lirisoft.com`} />
            <Input name="issueDate" type="date" label="Issue Date" />
            <Input name="returnDate" type="date" label="Return Date" />
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 text-white px-8 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddProduct;
