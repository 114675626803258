import DataTable from "components/DataTable";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
interface Column {
  key: string;
  label: string;
  actions?: (row: Row) => React.ReactNode;
  excludeExport?: boolean;
}
interface Row {
  id: number;
  [key: string]: ReactNode;
}
interface DataTableProps {
  data: Row[];
  columns: Column[];
  itemsPerPage?: number;
  onAdd?: () => void;
  loading?: boolean;
}
const InvetoriesList: React.FC<DataTableProps> = ({ data, columns, onAdd, loading }) => {
  const { t } = useTranslation();
  return (
    <DataTable data={data} columns={columns} title={t("rented-inventories.header")} onAddBtnClick={onAdd} loading={loading} />
  );
};
export default InvetoriesList;
