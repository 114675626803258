import DataTable from "components/DataTable";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Column {
  key: string;
  label: string;
  actions?: (row: Row) => React.ReactNode;
  excludeExport?: boolean;
}
interface Row {
  id: number;
  [key: string]: ReactNode;
}
interface DataTableProps {
  data: Row[];
  columns: Column[];
  itemsPerPage?: number;
  loading?: boolean;
  onAdd?: () => void;
}
const LeavesList: React.FC<DataTableProps> = ({ data, columns, loading, onAdd }) => {
  const { t } = useTranslation();
  return <DataTable data={data} columns={columns} title={t('leave.header')} loading={loading} onAddBtnClick={onAdd}/>;
};
export default LeavesList;
