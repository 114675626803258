/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import InvetoriesList from "./InvetoriesList";
import inventoryManager from "services/Inventory/inventoryManager";
import { FaEdit, FaEye, FaMobileAlt, FaLaptop, FaTools, FaHistory } from "react-icons/fa";
import Modal from "components/Modal";
import ViewProduct from "./ViewProduct";
import AddProduct from "./AddProduct";
import DeleteInventory from "./DeleteInventory";
import EditInventory from "./EditInventory";
import Badge from "components/Badge";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import { useAppContext } from "contexts";
import History from "./history";


export default function Invetories() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [Invetories, setInvetories] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [productData, setProductData] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [histroy, setHistroy] = useState(false);
  const [hData, sethData] = useState();

  const { t } = useTranslation();
  const { userRole } = useAppContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleView = (row: any) => {
    console.log("row", row);
    setProductData(row);
    setOpenModal(true);
  };

  //  Here added Function for Capitalize First Letter for Owner Grid

  const handleFirstLetter = (string : string) =>{
    if(!string){
      return false ;
    } else
    {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
  const tableColumns = [
    {
      key: "assign",
      label: `${t("inventories.assign")}`,
      className: "text-gray-900  font-semibold",
      render: (row: any) => (row.length > 0 ? row : "unassigned "),
    },
    { key:"owner", label: `${t("inventories.owner")}`, bold: true,
      render : (row : any) => handleFirstLetter(row),
    },
    {
      key: "location",
      label: `${t("inventories.location")}`,
      render: (row: any) => {
        if (row === "Bangalore") {
          return <Badge>{row}</Badge>;
        } else {
          return (
            <Badge className="bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">
              {row}
            </Badge>
          );
        }
      },
    },
    { key: "model", label: `Model`, bold: true },
    { key: "brand", label: `${t("inventories.brand")}`, bold: true },
    {
      key: "assignDate",
      label: `${t("inventories.assignDate")}`,
      type: "Date",
      bold: true,
    },
    {
      key: "type",
      label: `${t("inventories.type")}`,
      render: (row: any) => {
        if (row === "Mobile") {
          return <FaMobileAlt />;
        } else if (row === "Laptop") {
          return <FaLaptop />;
        } else if (row === "Tablet") {
          return <FaLaptop />;
        } else if (row === "SIM") {
          return <FaLaptop />;
        } else {
          return <FaTools />;
        }
      },
    },
    {
      key: "status",
      label: `${t("inventories.status")}`,
      render: (row: any) => {
        const updateStatus = row.length > 0 ? row : "Status Not Defined";
        if (row === "Running") {
          return (
            <span className="flex items-center text-sm font-medium text-gray-900 dark:text-white me-3">
              <span className="flex w-2.5 h-2.5 bg-teal-500 rounded-full me-1.5 flex-shrink-0"></span>
              {row}
            </span>
          );
        } else if (row === "Not working") {
          return (
            <span className="flex items-center text-sm font-medium text-gray-900 dark:text-white me-3">
              <span className="flex w-2.5 h-2.5 bg-red-500 rounded-full me-1.5 flex-shrink-0"></span>
              {updateStatus}
            </span>
          );
        } else if (row.length > 0) {
          return (
            <span className="flex items-center text-sm font-medium text-gray-900 dark:text-white me-3">
              <span className="flex w-2.5 h-2.5  bg-yellow-300 rounded-full me-1.5 flex-shrink-0"></span>
              {row}
            </span>
          );
        } else {
          return (
            <span className="flex items-center text-sm font-medium text-gray-900 dark:text-white me-3">
              <span className="flex w-2.5 h-2.5  bg-yellow-300 rounded-full me-1.5 flex-shrink-0"></span>
              {row}
            </span>
          );
        }
      },
    },

    {
      key: "actions",
      label: `${t("inventories.actions")}`,
      excludeExport: true,
      actions: (row: any) => (
        <div className="flex flex-start">
          {(userRole === 2 || userRole === 3) && (
            <span className="btn-primary px-2 py-2 mr-2 rounded-lg" onClick={() => handleEdit(row)}>
              <FaEdit className="text-white" />
            </span>
          )}

          <span className="btn-primary px-2 py-2 mr-2 rounded-lg" onClick={() => handleView(row)}>
            <FaEye className="text-white" />
          </span>
          <span
            className="btn-primary px-2 py-2 mr-2 rounded-lg"
            onClick={() => {
              setHistroy(true);
              sethData(row);
            }}
          >
            <FaHistory className="text-white" />
          </span>
        </div>
      ),
    },
  ];
  
  useEffect(() => {
    setLoading(true);
    inventoryManager
      .getInventoryData()
      .then((res) => {
        const sortedData = res.data.assets.sort((a, b) => {
          if (!a.assign && !b.assign) return 0;
          if (!a.assign) return 1;
          if (!b.assign) return -1;
          return a.assign.toLowerCase().localeCompare(b.assign.toLowerCase());
        });
        setInvetories(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching Reimbursement list:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh]);
  const [addProduct, setAddPrdouct] = useState(false);
  const handleAddfunction = () => {
    setAddPrdouct(!addProduct);
  };
  const [deleteProduct, setDeletePrdouct] = useState(false);

  const [editProduct, setEditPrdouct] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEdit = (row: any): void => {
    setProductData(row);
    setEditPrdouct(true);
  };
  const BreadCrumbList = [
    { name: "Inventory", link: "#" },
    { name: "list", link: "#" },
  ];
  const [selectedInventory, setSelectedInventory] = useState("All");
  useEffect(() => {
    console.log("selectedInventory :>> ", selectedInventory);
    filterData(selectedInventory);
  }, [Invetories]);
  const handleButtonClick = (value: string) => {
    setSelectedInventory(value);
    filterData(value);
  };
  const filterData = (type: string) => {
    const filtered = Invetories.filter((item: any) => {
      if (type === "All") {
        return item;
      } else if (item.asset_type === type) {
        return item;
      }
    });
    console.log("filtered", filtered.length);
    setFilteredData(filtered);
    // console.log("type", type);
    // setFilteredData(Invetories);
  };

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />

      {(userRole === 2 || userRole === 3) && (
        <div className="flex space-x-4">
          <button
            className={`py-2 rounded-lg px-8 border ${selectedInventory === "All" ? "bg-blue-700 text-white" : "bg-white"}`}
            onClick={() => handleButtonClick("All")}
          >
            {t("All")}
          </button>
          <button
            className={`py-2 px-8 rounded-lg border  ${selectedInventory === "Own" ? "bg-blue-700 text-white" : "bg-white"}`}
            onClick={() => handleButtonClick("Own")}
          >
            {t("Own")+"\t"+t("Inventory")}
          </button>
          <button
            className={`py-2 px-8 rounded-lg border  ${selectedInventory === "Rental" ? "bg-blue-700 text-white" : "bg-white"}`}
            onClick={() => handleButtonClick("Rental")}
          >
            {t("Rental") +"\t"+ t("Inventory")}
          </button>
          <button
            className={`py-2 px-8 rounded-lg border  ${selectedInventory === "Sample" ? "bg-blue-700 text-white" : "bg-white"}`}
            onClick={() => handleButtonClick("Sample")}
          >
           {t("Sample") +"\t"+ t("Inventory")}
          </button>
        </div>
      )}
      {userRole === 2 || userRole === 3 ? (
        <InvetoriesList
      
          data={filteredData}
          columns={tableColumns}
          onAdd={handleAddfunction}
          loading={loading}
          selectedInventory={selectedInventory}
        />
      ) : (
        <InvetoriesList
          data={filteredData}
          columns={tableColumns}
          loading={loading}
          selectedInventory={selectedInventory}
        />
      )}

      {openModal && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} header="View Inventory">
          {productData && <ViewProduct product={productData} />}
        </Modal>
      )}

      {addProduct && (
        <Modal
          isOpen={addProduct}
          onClose={() => setAddPrdouct(false)}
          header="Add Inventory"
          customCss={{
            width: "900px !important",
            top : "0px"
          }}
        >
          {addProduct && <AddProduct onClose={() => setAddPrdouct(false)} setRefresh={setRefresh} refresh={refresh} />}
        </Modal>
      )}
      {deleteProduct && (
        <Modal isOpen={deleteProduct} onClose={() => setDeletePrdouct(false)} header="Delete Inventory">
          {deleteProduct && <DeleteInventory onClose={() => setDeletePrdouct(false)} data={productData} />}
        </Modal>
      )}
      {editProduct && (
        <Modal
          isOpen={editProduct}
          onClose={() => setEditPrdouct(false)}
          header="Edit Inventory"
          customCss={{
            width: "900px !important",
          }}
        >
          {editProduct && (
            <EditInventory
              onClose={() => setEditPrdouct(false)}
              data={productData}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          )}
        </Modal>
      )}
      {histroy && (
        <Modal
          isOpen={histroy}
          onClose={() => setHistroy(false)}
          header="Inventory History"
          customCss={
            {
              position : "absolute",
              top: "10px",
              width: "900px !important",
            }
          }
        >
          {histroy && <History onClose={() => setHistroy(false)} row={hData} />}
        </Modal>
      )}
    </>
  );
}
