import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import apiService from "services/apiService";
import ProfileDetails from "./ProfileDetails";

export default function Profile() {
  const [user, setUser] = useState();
  const [refresh, setRefresh] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    apiService
      .get("api/user/me")
      .then((res) => {
        // console.log("api/user/me", res);
        setUser(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(`profile err: ${err}`);
      });
  }, [refresh]);
  return (
    <>
      {user && <ProfileDetails user={user} setRefresh={setRefresh} />}
      {loader && <Loader />}
    </>
  );
}
