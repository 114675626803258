/* eslint-disable */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import apiService from "services/apiService";
import months from "util/months";
import * as XLSX from "xlsx";
import DataDisplay from "./DataDisplay";
import { sortByKey } from "util/sortOptions";
import { getUnixTime } from "util/epochTime";
import { BreadCrumb } from "components/BreadCrumb";
import { link } from "fs";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  email: yup.string().notRequired().nullable(),
  project: yup.string().notRequired().nullable(),
  year: yup.number().required("Year is Required"),
  month: yup.string().required("Month is Required"),
});
interface OptionProps {
  value: string;
  label: string;
}
const YearOption = [
  {
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  },
  {
    label: new Date().getFullYear() - 1,
    value: new Date().getFullYear() - 1,
  },
];
export default function ExportTimeSheet() {
  const methods = useForm({ resolver: yupResolver(schema) });
  const [data, setData] = useState<OptionProps[]>([{ value: "", label: "All" }]);
  const [project, setProject] = useState<OptionProps[]>([{ value: "", label: "All" }]);
  const [timesheet, setTimesheet] = useState();
  const [enable, setEnable] = useState(true);
  const [payload1, setPayload1] = useState<any>(null);
  useEffect(() => {
    apiService
      .get("api/users")
      .then((res) => {
        const getEmailOptions = (emailArray: any[]) => {
          return emailArray.map((email: any) => ({
            value: email.email,
            label: email.displayName,
          }));
        };
        const emailOptions = getEmailOptions(res.data);
        const sortedArray = sortByKey(emailOptions, "label");
        const tempArray = [{ value: "", label: "All" }, ...sortedArray];
        setData(tempArray);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  useEffect(() => {
    apiService
      .get("api/project")
      .then((res) => {
        // console.log('res.data', res.data)
        const getProjectOptions = (projectArray: any[]) => {
          return projectArray.map((project: any) => ({
            value: project.projectId,
            label: project.name,
          }));
        };
        const ProjectOptions = getProjectOptions(res.data);
        const tempArray = [{ value: "", label: "All" }, ...ProjectOptions];
        const sortedArray = sortByKey(tempArray, "label");
        setProject(sortedArray);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  const getUserNameByEmail = (email: string): string => {
    const user = data.find((user) => user.value === email);
    return user ? user.label : "";
  };

  // Function to get project name based on projectId
  const getProjectNameById = (projectId: string): string => {
    const projectObj = project.find((proj) => proj.value === projectId);
    return projectObj ? projectObj.label : "";
  };

  function exportTimeSheetToExcel(data: any[], userName: string, projectName: string) {
    const wb = XLSX.utils.book_new();
    let sheetCounter = 1; // Counter to ensure unique sheet names

    data.forEach((item) => {
      console.log("data", item);
      const user_name = getUserNameByEmail(item.email);
      console.log("user_name", user_name);
      item.data.forEach((detail: any) => {
        const project_name = getProjectNameById(detail.projectId);
        if (detail.detail.length > 0) {
          let wsName = `${user_name}_${project_name}`.slice(0, 31);
          // Check if sheet with wsName already exists
          while (wb.Sheets[wsName]) {
            wsName = `${user_name}_${project_name}}_${sheetCounter++}`.slice(0, 31);
          }

          const wsData = detail.detail.map((task: any) => ({
            Date: getUnixTime(task.date),
            Task: task.task,
            Hours: task.hour,
            Comment: task.comment,
          }));
          const ws = XLSX.utils.json_to_sheet(wsData);

          // Ensure ws.SheetNames is initialized as an array
          if (!ws.SheetNames) {
            ws.SheetNames = [];
          }

          // Add the wsName to SheetNames array
          ws.SheetNames.push(wsName);

          // Add metadata to the worksheet
          ws.Metadata = {
            userName,
            projectName,
          };

          XLSX.utils.book_append_sheet(wb, ws, wsName);
        }
      });
    });

    // Check if any sheets were added to the workbook
    if (wb.SheetNames.length > 0) {
      XLSX.writeFile(wb, `TimeSheet_${new Date().toISOString()}.xlsx`);
    } else {
      alert("Data is empty. No sheets to export.");
    }
  }

  function buildQueryString(payload: any): string {
    // const params = Object.keys(payload)
    //   .filter((key) => payload[key] !== undefined && payload[key] !== "")
    //   .map((key) => {
    //     const encodedKey = encodeURIComponent(key);
    //     const encodedValue = (payload[key]);
    //     return `${encodedKey}=${encodedValue}`;
    //   })
    //   .join("&");
    const params = Object.keys(payload)
      .filter((key) => payload[key] !== undefined && payload[key] !== "" && payload[key] !== "All")
      .map((key) => {
        const encodedKey = encodeURIComponent(key);
        let value = payload[key];

        // Convert projectId to a number
        if (key === "projectId") {
          value = Number(value);
        }
        const encodedValue = payload[key];
        return `${encodedKey}=${encodedValue}`;
      })
      .join("&");

    return `api/timesheet?${params}`;
  }

  useEffect(() => {
    methods.setValue("email", data[0].value);
    methods.setValue("project", project[0].value);
    methods.setValue("year", YearOption[0].value);
    methods.setValue("month", months[new Date().getMonth()].value);
  }, []);
  const onSubmit: SubmitHandler<any> = (payload: any) => {
    console.log("payload", payload);
    setPayload1(payload);
    payload.projectId = payload.project;
    delete payload.project;
    const queryString = buildQueryString(payload);
    console.log("urldata", queryString);
    apiService
      .get(queryString)
      .then((res) => {
        setTimesheet(res.data);
        console.log("payload", payload);
        if (payload.email == "" && payload.projectId == "") {
          setEnable(true);
        } else {
          setEnable(false);
        }
        //  console.log('payload.email', payload.email,enable,payload.email.length)
        console.log("res.data", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const onExport = () => {
  //   if (timesheet) exportTimeSheetToExcel(timesheet);
  // };
  const onExport = () => {
    if (timesheet && payload1) {
      const email = payload1.email || "";
      const projectId = payload1.projectId || ""; // Ensure projectId is defined
      const project1 = project.find((proj) => proj.value === projectId);
      const projectName = project1 ? project1.label : "All";
      const userName = email === "" ? "All" : data.find((user) => user.value === email)?.label || "";

      exportTimeSheetToExcel(timesheet, userName, projectName);
    }
  };

  const{t} = useTranslation();

  const BreadCrumbList = [
    {name : "Export Time Sheet", link:"#" },
    {name : "List" , link : "#"}
  ];

  return (
    <div>
      {/* Here Adding  page path and their grid title header  */}

      <BreadCrumb BreadCrumbList={BreadCrumbList} ></BreadCrumb>
      
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid gap-4 grid-cols-4 place-items-center	sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-6 ">
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> {t("User")}</label>
              <Select
                {...methods.register("email", { required: true })}
                options={data}
                placeholder="Select Employee"
                className="w-full"
                onChange={(selectedOption) => {
                  selectedOption && methods.setValue("email", selectedOption.value);
                }}
                defaultValue={data[0]}
              />
              {methods.formState.errors.email && (
                <span className="text-red-500 text-sm mt-1">{methods.formState.errors.email.message}</span>
              )}
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> {t("Projects")}</label>
              <Select
                {...methods.register("project", { required: true })}
                options={project}
                placeholder="Select Project"
                className="w-full"
                onChange={(selectedOption) => {
                  selectedOption && methods.setValue("project", selectedOption.value);
                }}
                defaultValue={project[0]}
              />
              {methods.formState.errors.project && (
                <span className="text-red-500 text-sm mt-1">{methods.formState.errors.project.message}</span>
              )}
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> {t("Year")}</label>
              <Select
                {...methods.register("year", { required: true })}
                options={YearOption}
                placeholder="Select Year"
                className="w-full"
                onChange={(selectedOption) => {
                  selectedOption && methods.setValue("year", selectedOption.value);
                }}
                defaultValue={YearOption[0]}
              />
              {methods.formState.errors.year && (
                <span className="text-red-500 text-sm mt-1">{methods.formState.errors.year.message}</span>
              )}
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> {t("Month")}</label>
              <Select
                {...methods.register("month", { required: true })}
                options={months}
                placeholder="Select month"
                className="w-full"
                onChange={(selectedOption) => {
                  selectedOption && methods.setValue("month", selectedOption.value);
                }}
                defaultValue={months[new Date().getMonth()]}
              />
              {methods.formState.errors.month && (
                <span className="text-red-500 text-sm mt-1">{methods.formState.errors.month.message}</span>
              )}
            </div>
          </div>
          <div className="flex justify-center gap-5">
            <button
              className="justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium w-24 rounded-lg text-sm  py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="submit"
            >
              {t("View")}
            </button>

            <button
              className={`justify-center text-white ${enable ? `bg-gray-300` : `bg-blue-700`}   focus:ring-blue-300 font-medium rounded-lg text-sm w-24 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
              disabled={enable}
              onClick={onExport}
            >
              {t("Export")}
            </button>
          </div>
        </form>
      </FormProvider>

      {timesheet && <DataDisplay data={timesheet} userName={getUserNameByEmail} projectName={getProjectNameById} />}
    </div>
  );
}
