/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "components/Input";
import { useAppContext } from "contexts";
import React, { ReactNode, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Select from "react-select";
import { UpdatePayload } from "services/Leave/leaveInterface";
import leaveManager from "services/Leave/leaveManager";
import { getYear, getMonthByDate, convert } from "util/Util";
import * as yup from "yup";

const validationSchema = yup.object({
  leaveFrom: yup.string().required("Leave From is required"),
  leaveTo: yup.string().required("Leave To is required"),
  // leaveFor: yup.string().required("Leave For is required"),
  days: yup.string(),
  leaveType: yup.string().required("Leave Type is required"),
  reason: yup.string().required("Leave Reason is required"),
  status: yup.string(),
  comment: yup.string(),
});

interface IProps {
  onUpdate: () => void;
  selectedRow: any;
  onClose: () => void;
  onError: () => void;
}

// const leaveForOptions = [
//   { value: 1, label: "Full Day" },
//   { value: 0.5, label: "Half Day" },
// ];
const data1 = [
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
  { value: "Submitted", label: "Submitted" },
];

const leaveTypeOptions = [
  { value: "Sick leave", label: "Sick Leave" },
  { value: "Urgent leave", label: "Urgent leave" },
  {
    value: "Maternity and paternity leave",
    label: "Maternity and paternity leave",
  },
  {
    value: "Public and religious holidays",
    label: "Public and religious holidays",
  },
  { value: "Bereavement leave", label: "Bereavement leave" },
  { value: "Time off in lieu (TOIL)", label: "Time off in lieu (TOIL)" },
  { value: "Unpaid leave", label: "Unpaid leave" },
  { value: "Paid leave", label: "Paid leave" },
  { value: "Other leave", label: "Other leave" },
];

const LeaveDetails = ({ onUpdate, selectedRow, onClose, onError }: IProps) => {
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const [leaveFrom, setLeaveFrom] = useState("");
  const [leaveTo, setLeaveTo] = useState("");
  const [days, setDays] = useState(0);
  const [selectedLeaveFor] = useState(1);
  const [selectedLeaveType, setSelectedLeaveType] = useState("Sick leave");
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");
  const [selected, setSelected] = useState<any>();
  const { userRole, user } = useAppContext();
  const onSubmit = async (data: any) => {
    console.log("data :>> ", days);
    onClose();
    const payload: UpdatePayload = {
      id: selectedRow?.id,
      leaveTo: data.leaveTo || selectedRow?.leaveTo,
      leaveFrom: data.leaveFrom || selectedRow?.leaveFrom,
      unit: days || selectedRow?.unit,
      reason: data.reason || selectedRow?.reason,
      leaveType: data.leaveType || selectedRow?.leaveType,
      status: data.status || selectedRow?.status,
      department: selectedRow?.department,
      comment: comment,
      year: getYear() || selectedRow?.year,
      month: getMonthByDate(data.leaveTo) || selectedRow?.month,
      email: data.email || selectedRow?.email,
      leaveAppliedDate: selectedRow?.leaveAppliedDate,
    };
    leaveManager
      .updateLeave(payload)
      .then((res) => {
        console.log(res);
        onUpdate();
      })
      .catch((err) => {
        console.log(`err: ${err}`);
        onError();
      });
  };
  useEffect(() => {
    setDays(selectedRow.unit);
    methods.setValue("days", selectedRow.unit);
  }, []);
  useEffect(() => {
    if (leaveFrom && leaveTo) {
      const convertLeaveFrom: Date = new Date(leaveFrom);
      const convertLeaveTo: Date = new Date(leaveTo);
      let days = 0;
      for (let d = new Date(convertLeaveFrom); d <= convertLeaveTo; d.setDate(d.getDate() + 1)) {
        if (d.getDay() !== 6 && d.getDay() !== 0) {
          days++;
        }
      }
      if (selectedLeaveFor === 0.5) {
        days -= 0.5;
      }
      setDays(days);
    }
  }, [leaveFrom, leaveTo, selectedLeaveFor]);

  const onLeaveFromChanged = (e: any) => {
    setLeaveFrom(e.target.value);
  };

  const onLeaveToChanged = (e: any) => {
    setLeaveTo(e.target.value);
  };

  const onLeaveTypeChanged = (selectedOption: any) => {
    methods.clearErrors("leaveType");
    setSelectedLeaveType(selectedOption.value);
    methods.setValue("leaveType", selectedOption.value);
  };

  const onDaysChange = () => {
    console.log(`on days change`);
  };

  useEffect(() => {
    // methods.setValue("leaveType", selectedLeaveType);
    // methods.setValue("leaveFor", JSON.stringify(leaveForOptions[0].value));
  }, [methods]);

  useEffect(() => {
    if (selectedRow) {
      setLeaveFrom(convert(selectedRow.leaveFrom));
      setLeaveTo(convert(selectedRow.leaveTo));
      setDays(selectedRow.unit);
      setReason(selectedRow.reason);
      setSelectedLeaveType(selectedRow.leaveType);
      // setSelectedLeaveFor(selectedRow.leaveType);
      methods.setValue("leaveFrom", convert(selectedRow.leaveFrom));
      methods.setValue("leaveTo", convert(selectedRow.leaveTo));
      methods.setValue("reason", selectedRow.reason);
      methods.setValue("comment", selectedRow.comment);
      methods.setValue("leaveType", selectedRow.leaveType);
      // methods.setValue("leaveFor", selectedRow.leaveType);
    }
  }, []);

  const handleReasonChange = (e: any) => {
    setReason(e.target.value);
  };
  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };
  const minLeaveToDate = leaveFrom ? new Date(leaveFrom).toISOString().split("T")[0] : "";
  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="form-group grid grid-rows-4 gap-y-6 gap-x-6">
            <div className="form-group grid grid-cols-2 gap-x-4">
              <div className="flex flex-col">
                <Input
                  name="leaveFrom"
                  type="date"
                  required
                  label="Leave From"
                  onChange={onLeaveFromChanged}
                  value={leaveFrom}
                />
              </div>
              <div className="flex flex-col">
                <Input
                  type="date"
                  label="Leave To"
                  required
                  name="leaveTo"
                  onChange={onLeaveToChanged}
                  value={leaveTo}
                  min={minLeaveToDate}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4">
              <div className="flex flex-col">
                <Input type="number" label="Days" name="days" required value={days} onChange={onDaysChange} disabled />
              </div>
              <div className="flex flex-col">
                <label htmlFor="project" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Leave Type  <span className="text-red-500"> *</span>
                </label>
                <Select
                  options={leaveTypeOptions}
                  {...methods.register("leaveType")}
                  name="leaveType"
                  onChange={onLeaveTypeChanged}
                  value={leaveTypeOptions.find((op) => op.value === selectedLeaveType)}
                />
              </div>
            </div>
            <div className="grid grid-cols">
              <div className="w-full">
                <Input
                  type="textbox"
                  label="Leave Reason"
                  placeholder="Reason"
                  name="reason"
                  required
                  value={reason}
                  onChange={handleReasonChange}
                />
              </div>
              {(userRole === 2 || userRole === 3) && selectedRow.email !== user.email && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Status  <span className="text-red-500"> *</span></label>
                  <Select
                    {...methods.register("status", { required: true })}
                    options={data1}
                    placeholder="Select Status"
                    className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    onChange={(selectedOption) => {
                      selectedOption && methods.setValue("status", selectedOption.value);
                      if (selectedOption) setSelected(selectedOption);
                    }}
                    value={selected}
                    defaultValue={data1.find((option) => option.value === selectedRow.status)}
                  />
                  {methods.formState.errors.status && (
                    <span className="text-red-500 text-sm mt-1">
                      {methods.formState.errors.status.message as ReactNode}
                    </span>
                  )}
                </div>
              )}
            </div>
            {(userRole === 2 || userRole === 3) && selectedRow.email !== user.email && (
              <div className="w-full">
                <Input
                  type="textarea"
                  label="Comment"
                  placeholder="Comment"
                  name="comment"
                  required
                  value={comment}
                  onChange={handleCommentChange}
                />
              </div>
            )}

            <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
              <button
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
                onClick={onClose}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 ms-3"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default LeaveDetails;
