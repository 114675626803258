import React, { ReactNode, ComponentType } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { storageService } from "services/storageService";
import AdminLayout from "../layouts/AdminLayout";

interface PrivateRouteProps {
  Layout: ComponentType<{ children: ReactNode }>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  Layout = AdminLayout,
}) => {
  const token = storageService.getItem("accessToken");
  // const isLogin = true;
  if (token) {
    return (
      <>
        <Layout>
          <Outlet />
        </Layout>
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
