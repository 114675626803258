/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/en-gb";
import Select from "react-select";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import Modal from "components/Modal";
import apiService from "services/apiService";
import CustomToolbar from "./CustomToolbar";
import AddTimeSheet from "./AddTimeSheet";
import EventInfo from "./EventInfo";
import Tooltip from "components/Tooltip";
import { BreadCrumb } from "components/BreadCrumb";
const Localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);
import { useTranslation } from "react-i18next";
import { useAppContext } from "contexts";
import { sortByKey } from "util/sortOptions";
import EditTimeSheet from "./EditTimeSheet";
import { storageService } from "services/storageService";

const TimeSheetCalendar: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [events, setEvents] = useState<any>([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [eventDetailsModal, setEventDetailsModal] = useState(false);
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [projects, setProjects] = useState<any>([]);
  const [wholeData, setWholeData] = useState();
  const { t } = useTranslation();
  const { user, userRole } = useAppContext();
  const [refresh, setRefresh] = useState(0);
  const [selectedDate, setSelectedDate] = useState();

  const handleProjectChange = (selectedOption: any) => {
    setSelectedProject(selectedOption.value);
  };

  const handleUserChange = (selectedOption: any) => {
    setSelectedProject(null);
    setProjects(null);
    setSelectedUser(selectedOption.value.email);
    setProjects(selectedOption?.value?.userProjectWithAssignedDate);
  };

  const handleEventSelect = (event: any) => {
    if (event.isHoliday || event.isLeave) {
      return;
    }
    setSelectedEvent(event);
    setEventDetailsModal(true);
  };
  const projectOptions: { value: any; label: any }[] =
    projects && projects.length > 0
      ? projects.map((project: any) => ({
          value: project.projectId,
          label: project.name,
        }))
      : [];
  const TempUsers =
    users &&
    users.map((res: any) => ({
      value: res,
      label: res.displayName,
    }));
  const userOption = sortByKey(TempUsers, "label");
  const onSelectSlot = (e: any) => {
    const isHoliday = holidays.some(
      (holiday: any) => new Date(holiday.start).toDateString() === e.start.toDateString()
    );
    const isSlotDisabled = leaves.some(
      (event: any) => (e.start >= event.start && e.start <= event.end) || (e.end >= event.start && e.end <= event.end)
    );

    if (isSlotDisabled) {
      return;
    }

    if (isHoliday) {
      return;
    }
    if (moment(e.start).format("MMM") === currentMonth) {
      setSelectedDate(e.start);
      setOpenModal(true);
    }
  };
  const dayPropGetter = useCallback(
    (date: Date) => ({
      ...((moment(date).day() === 6 || moment(date).day() === 0) && {
        style: {
          backgroundColor: "#f6f6f6",
          // color: "red ",
        },
      }),
    }),
    []
  );
  const handleNavigate = (date: Date) => {
    console.log("Current month:", moment(date).format("MMM"));
    console.log("Current year:", moment(date).format("YYYY"));
    setCurrentMonth(`${moment(date).format("MMM")}`);
    setCurrentYear(moment(date).format("YYYY"));
  };
  const handleView = () => {
    const currentDate = new Date();
    setCurrentMonth(`${moment(currentDate).format("MMM")}`);
    setCurrentYear(moment(currentDate).format("YYYY"));
  };

  useEffect(() => {
    handleView();
  }, []);
  const getTimeSheetData = (payload: any) => {
    const queryParams = new URLSearchParams({
      year: payload.year.toString(),
      month: payload.month,
      ...(payload.projectId && { projectId: payload.projectId.toString() }),
      ...(payload.email && { email: payload.email }),
    });
    const url = `api/timesheet?${queryParams.toString()}`;
    console.log("url => ", url);
    return apiService.get(url);
  };
  function removeDuplicates(arr: any) {
    const map = new Map();

    arr.forEach((entry: any) => {
      const key = `${entry.title}-${entry.projectId}-${entry.hour}-${entry.start}`;
      if (!map.has(key)) {
        map.set(key, entry);
      }
    });

    return Array.from(map.values());
  }
  useEffect(() => {
    const payload = {
      year: currentYear,
      month: currentMonth,
      email: selectedUser,
      projectId: selectedProject,
    };
    // console.log("payload", payload);
    if (payload.year) {
      apiService
        .get(`api/timesheet/${currentYear}/${currentMonth}`)
        .then((res) => {
          console.log("res.data", res.data);
          setWholeData(res.data);
          const timesheetdata: any[] = [];
          const AA = res.data;
          console.log("res.data.month :>> ", res.data.month);
          const monthString = res.data.month;
          const monthIndex = moment().month(monthString).month();
          if (AA) {
            const eventData = AA.data;
            console.log("eventData :>> ", eventData);
            eventData &&
              eventData.map((val: any, i: number) => {
                if (eventData) {
                  const details = eventData[i].detail;
                  const event = details
                    .filter((detail: any) => detail.task.trim() !== "")
                    .map((detail: any) => ({
                      id: detail.id,
                      title: detail.task,
                      projectId: eventData[i].projectId,
                      hour: detail.hour,
                      // start: new Date(detail.day),
                      // end: new Date(detail.day),
                      start: moment()
                        .set({
                          date: detail.day,
                          month: monthIndex,
                          year: parseInt(currentYear, 10),
                          // hour: 9,
                        })
                        .toDate(),
                      end: moment()
                        .set({
                          date: detail.day,
                          month: monthIndex,
                          year: parseInt(currentYear, 10),
                          // hour: 19,
                        })
                        .toDate(),
                      allDay: true,
                    }));
                  timesheetdata.push(...event);
                }
              });
            console.log("timesheetdata :>> ", timesheetdata);
            const uniqueArray = timesheetdata.filter(
              (item, index, self) =>
                index ===
                self.findIndex((t) => t.title === item.title && t.hour === item.hour && t.start === item.start)
            );
            console.log("uniqueArray :>> ", uniqueArray);
            const uniqueEntries = removeDuplicates(uniqueArray);
            setEvents(uniqueEntries);
          }
        })
        .catch((err) => {
          console.log("get timesheet error :>>", err);
        });
    }
  }, [currentYear, currentMonth, refresh]);

  const eventPropGetter = (event: any) => {
    if (event.isHoliday || event.isLeave) {
      let style = {
        backgroundColor: event.isLeave ? "#bb2124" : "green",
        color: "white",
        // borderRadius: "5px",
        // border: "none",
        // display: "block",
      };
      return { style };
    } else {
      return event;
    }
  };

  const [holidays, setHolidays] = useState<any>([]);
  const [leaves, setLeaves] = useState<any>([]);
  useEffect(() => {
    const year = new Date().getFullYear();
    const da = storageService.getItem("user");
    const aaa = da && JSON.parse(da);
    const url = `/api/leave/data/${year}?email=${aaa.email}`;
    apiService
      .get(url)
      .then((res) => {
        console.log("leaves=>", res.data);
        const calendarEvents = res.data.map((leave: any) => ({
          id: leave.id,
          title: leave.leaveType, // You can also include other info in the title if needed
          start: new Date(leave.leaveFrom),
          end: new Date(leave.leaveTo),
          allDay: true,
          isLeave: true,
        }));
        setLeaves(calendarEvents);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  useEffect(() => {
    const currentDate = new Date();
    const year = moment(currentDate).format("YYYY");
    const url = `/api/holiday/${year}`;
    console.log("holiday url", url);
    apiService
      .get(url)
      .then((res) => {
        console.log("holidays", res.data);
        const holidayEvents = res.data.map((holiday: any) => ({
          id: holiday.holidayId,
          title: holiday.occasion,
          start: new Date(holiday.holidayDate).toISOString(),
          end: new Date(holiday.holidayDate).toISOString(),
          allDay: true,
          isHoliday: true,
        }));
        console.log("holidayEvents", holidayEvents);
        setHolidays(holidayEvents);
      })
      .catch((err) => {
        console.log("get holidays error", err);
      });
  }, []);
  const CustomEvent = ({ event }: any) => <Tooltip content={event.title}> {event.title}</Tooltip>;
  const BreadCrumbList = [
    { name: "Timesheet", link: "#" },
    { name: "Add Timesheet", link: "#" },
  ];
  useEffect(() => {
    if (user) {
      setSelectedUser(user.email);
      setProjects(user.userProjectWithAssignedDate);
    }
    // if (userRole) {
    //   if (userOption && user) {
    //     console.log("user.userProjectWithAssignedDate", user.userProjectWithAssignedDate);
    //     const data = userOption.find((option) => option.value.email === user.email);
    //     setSelectedUser(user.email);
    //     setProjects(user.userProjectWithAssignedDate);
    //     if (data) {
    //       // setSelectedProject(data.value);
    //       // setProjects(data?.value?.userProjectWithAssignedDate);
    //     }
    //   }
    // }
  }, [user]);
  const [taskId, setTaskId] = useState(0);
  const [edit, setEdit] = useState(false);
  const handleEdit = (id: number) => {
    console.log("id", id);
    setTaskId(id);
    setEventDetailsModal(false);
    setEdit(true);
  };

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div className=" bg-gray-50 p-4 ">
        <Modal isOpen={edit} onClose={() => setEdit(false)} header="Edit Timesheet">
          <EditTimeSheet
            projects={projects}
            selectedProj={selectedProject}
            selectedUser={selectedUser}
            month={currentMonth}
            year={currentYear}
            onClose={() => setEdit(false)}
            wholeData={wholeData}
            setRefresh={setRefresh}
            refresh={refresh}
            selectedDate={selectedDate}
            taskId={taskId}
          />
        </Modal>
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} header="Add Timesheet">
          <AddTimeSheet
            projects={projects}
            selectedProj={selectedProject}
            selectedUser={selectedUser}
            month={currentMonth}
            year={currentYear}
            onClose={() => setOpenModal(false)}
            wholeData={wholeData}
            setRefresh={setRefresh}
            refresh={refresh}
            selectedDate={selectedDate}
          />
        </Modal>
        {selectedEvent && eventDetailsModal && (
          <Modal isOpen={eventDetailsModal} onClose={() => setEventDetailsModal(false)} header="Timesheet Info">
            <EventInfo selectedEvent={selectedEvent} projects={projects} handleEdit={handleEdit} />
          </Modal>
        )}

        <div className="flex justify-end hidden ">
          {!userRole && (
            <>
              {users && (
                <div className="me-2 w-64	" style={{ zIndex: 9999 }}>
                  <Select
                    name="user"
                    options={userOption}
                    value={userOption.find((option) => option.value === selectedUser)}
                    onChange={handleUserChange}
                    placeholder={t("timesheet.selectuser")}
                    className="select"
                    defaultValue={userOption.find((option) => option.value.email === user.email)}
                  />
                </div>
              )}
            </>
          )}
          <div className="me-2 w-64	" style={{ zIndex: 99 }}>
            <Select
              name="project"
              options={projectOptions}
              value={projectOptions.find((option) => option.value === selectedProject)}
              onChange={handleProjectChange}
              placeholder={t("timesheet.selectproject")}
              className="select"
              isDisabled={true}
              // isDisabled={!selectedUser}
              // defaultValue={projects?.value?.userProjectWithAssignedDate}
              // isMulti
            />
          </div>
          <Tooltip content="Add Timesheet">
            {" "}
            <button
              type="button"
              onClick={() => setOpenModal(true)}
              className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              + {t("timesheet.addTimesheet")}
            </button>
          </Tooltip>
        </div>
        <div className="p-4">
          <DragAndDropCalendar
            views={["month", "agenda"]}
            selectable
            localizer={Localizer}
            events={[...events, ...holidays, ...leaves]}
            onSelectEvent={(event) => handleEventSelect(event)}
            style={{ height: 500 }}
            onSelectSlot={onSelectSlot}
            defaultDate={new Date()}
            defaultView="month"
            resizable
            components={{
              toolbar: CustomToolbar,
              agenda: {
                event: CustomEvent,
              },
            }}
            popup
            dayPropGetter={dayPropGetter}
            onNavigate={handleNavigate}
            onView={handleView}
            eventPropGetter={eventPropGetter}
          />
        </div>
      </div>
    </>
  );
};

export default TimeSheetCalendar;
