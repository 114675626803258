import React from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/Input";
import { InventoryPayload } from "services/Inventory/inventoryInterface";

const schema = yup.object().shape({
  issuedTo: yup.string().email().required("Email is required"),
  rentStartDate: yup.date().required("Rent Start Date is required"),
  rentEndDate: yup.date().required("Rent End Date is required"),
  rentAmount: yup.number().required("Rent Amount is required"),
  paymentFrequency: yup.string().required("Payment Frequency is required"),
  issueDate: yup.date().required("Issue Date is required"),
  returnDate: yup.date().required("Return Date is required"),
});
interface editProductProps {
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}
const EditInventory = ({ onClose, data }: editProductProps) => {
  const methods = useForm({ resolver: yupResolver(schema), defaultValues: data });

  React.useEffect(() => {
    const rentStart = data.rentStartDate;
    const rentStartDate = new Date(rentStart);
    methods.setValue("rentStartDate", rentStartDate.toISOString().split("T")[0]);
    const rentEnd = data.rentEndDate;
    const rentEndDate = new Date(rentEnd);
    methods.setValue("rentEndDate", rentEndDate.toISOString().split("T")[0]);
    const issue = data.issueDate;
    const issueDate = new Date(issue);
    methods.setValue("issueDate", issueDate.toISOString().split("T")[0]);
    const returnD = data.returnDate;
    const returnDate = new Date(returnD);
    methods.setValue("returnDate", returnDate.toISOString().split("T")[0]);
  }, [methods.setValue]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit: SubmitHandler<InventoryPayload> = (data: InventoryPayload) => {
    console.log("data :>> ", data);
    // inventoryManager
    //   .updateInventory(data)
    //   .then((res) => {
    //     console.log("res :>> ", res);
    onClose();
    //   })
    //   .catch((err) => {
    //     console.log("err :>> ", err);
    //   });
  };

  return (
    <div className="bg-white  w-full  rounded-md" style={{ width: 900 }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid gap-4 grid-cols-2">
            <Input name="issuedTo" type="email" label="Issued To" placeholder={`Eg : vijay@lirisoft.com`} />
            <Input name="rentStartDate" type="date" label="Rent Start Date" />
            <Input name="rentEndDate" type="date" label="Rent EndDate" />
            <Input name="rentAmount" type="number" label="Rent Amount" />
            <Input name="paymentFrequency" type="string" label="Payment Frequency" />
            <Input name="issueDate" type="date" label="Issue Date" />
            <Input name="returnDate" type="date" label="Return Date" />
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 text-white px-8 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditInventory;
