import React from "react";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DateFormat = (children: any) => {
  return <p className="text-gray-800">{children && new Date(children).toLocaleDateString()}</p>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ViewProduct = (product: any) => {
  const { issuedTo, paymentFrequency, issueDate, rentAmount, rentEndDate, rentStartDate, returnDate } = product.product;
  console.log("product", product);
  return (
    <>
      {product && (
        <div className="max-w-xl mx-auto bg-white p-4 rounded-md shadow-md mb-4">
          <div className="flex justify-between mb-4">
            <h2 className="text-xl font-bold text-gray-800">{issuedTo}</h2>
            {/* <p className="text-sm text-gray-500">
              <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                {rentAmount}
              </span>
            </p> */}
          </div>
          {/* <p className="text-gray-600 mb-4">{rentAmount}</p> */}

          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm font-semibold text-gray-500">Rent Amount</p>
              <p className="text-gray-800">{rentAmount}</p>
            </div>
            <div>
              <p className="text-sm font-semibold text-gray-500">Payment Frequency</p>
              <p className="text-gray-800">{paymentFrequency}</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <p className="text-sm font-semibold text-gray-500">Issue Date</p>
              {DateFormat(issueDate)}
            </div>
            <div>
              <p className="text-sm font-semibold text-gray-500">Assigned Date</p>
              {DateFormat(rentStartDate)}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <p className="text-sm font-semibold text-gray-500">Rent EndDate</p>
              {DateFormat(rentEndDate)}
            </div>

            <div>
              <p className="text-sm font-semibold text-gray-500">Return Date</p>
              {DateFormat(returnDate)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewProduct;
