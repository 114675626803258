/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { FcEditImage } from "react-icons/fc";
import { dateFormate } from "util/Util";
import Image from "assets/avatar.png";

function ProfileCard({ user, udpateProfileImage }: any) {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="bg-white rounded-lg overflow-hidden shadow-lg">
        <div className="flex items-center justify-center bg-gray-300 h-80">
          <img
            src={user.imageUrl || Image}
            alt="User Image"
            className="w-48 h-48 rounded-full border-4 border-white"
            loading="lazy"
          />
          <label
            htmlFor="profileImageInput"
            className=" relative text-white-700 edit-button  hover:text-blue font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-white-500 dark:text-white-500 dark:hover:text-white dark:focus:ring-white-800 dark:hover:bg-white-500"
          >
            <input
              id="profileImageInput"
              type="file"
              accept="image/jpeg,image/jpg,image/png,image/bmp,image/tiff"
              title="Click here for update profile Photo"
              onChange={udpateProfileImage}
              className="hidden"
            />
            <FcEditImage size={25} />
          </label>
        </div>
        <div className="p-6 text-center">
          <h2 className="text-2xl font-semibold text-gray-800">
            {t("profile.displayName", { displayName: user.displayName })}
          </h2>
          <p className="text-gray-500">{t("profile.designation", { designation: user.designation })}</p>
        </div>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-2">{t("profile.userInfo")}</h2>

        <div className="space-y-1">
          {/* Email */}
          <div className="flex items-center space-x-4">
            <p className="font-semibold text-gray-700 dark:text-white">
              {t("profile.emailText")}
              <span className="ml-2 text-xl">:</span>
            </p>
            <p className="text-gray-600 dark:text-gray-300">{t("profile.email", { email: user.email })}</p>
          </div>

          {/* Mobile */}
          <div className="flex items-center space-x-4">
            <p className="font-semibold text-gray-700 dark:text-white">
              {t("profile.mobileText")}
              <span className="ml-2 text-xl">:</span>
            </p>
            <p className="text-gray-600 dark:text-gray-300">{t("profile.mobile", { mobile: user.mobileNumber })}</p>
          </div>

          {/* Office */}
          <div className="flex items-center space-x-4">
            <p className="font-semibold text-gray-700 dark:text-white">
              {t("profile.officeText")}
              <span className="ml-2 text-xl">:</span>
            </p>
            <p className="text-gray-600 dark:text-gray-300">{t("profile.office", { office: user.ofcLocation })}</p>
          </div>

          {/* DOB */}
          <div className="flex items-center space-x-4">
            <p className="font-semibold text-gray-700 dark:text-white">
              {t("profile.dobText")}
              <span className="ml-2 text-xl">:</span>
            </p>
            <p className="text-gray-600 dark:text-gray-300">{dateFormate(user.dob)}</p>
          </div>

          {/* DOJ */}
          <div className="flex items-center space-x-4">
            <p className="font-semibold text-gray-700 dark:text-white">
              {t("profile.doj")}
              <span className="ml-2 text-xl">:</span>
            </p>
            <p className="text-gray-600 dark:text-gray-300">{moment(user.doj).format("DD-MMM-YYYY").toUpperCase()}</p>
          </div>
        </div>

        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mt-4 mb-2">{t("profile.contact")}</h2>

        <div className="space-y-1">
          {/* Address */}
          <div className="flex items-center space-x-4">
            <p className="font-semibold text-gray-700 dark:text-white">
              {t("profile.addressText")}
              <span className="ml-2 text-xl">:</span>
            </p>
            <p className="text-gray-600 dark:text-gray-300">
              {t("profile.address", { address: user.permanentAddress })}
            </p>
          </div>

          {/* City */}
          <div className="flex items-center space-x-4">
            <p className="font-semibold text-gray-700 dark:text-white">
              {t("profile.cityText")}
              <span className="ml-2 text-xl">:</span>
            </p>
            <p className="text-gray-600 dark:text-gray-300">{t("profile.city", { city: user.city })}</p>
          </div>

          {/* State */}
          <div className="flex items-center space-x-4">
            <p className="font-semibold text-gray-700 dark:text-white">
              {t("profile.stateText")}
              <span className="ml-2 text-xl">:</span>
            </p>
            <p className="text-gray-600 dark:text-gray-300">{t("profile.state", { state: user.state })}</p>
          </div>

          {/* Pincode */}
          <div className="flex items-center space-x-4">
            <p className="font-semibold text-gray-700 dark:text-white">
              {t("profile.pincodeText")}
              <span className="ml-2 text-xl">:</span>
            </p>
            <p className="text-gray-600 dark:text-gray-300">{t("profile.pincode", { pincode: user.pinCode })}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ProfileCard);
