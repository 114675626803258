import apiService from "services/apiService";
import { GetLeavePayload, LeavePayload, LeaveResponse, UpdatePayload } from "./leaveInterface";
import { AxiosResponse } from "axios";

class LeaveManager {
    private url: string;
    constructor() {
        this.url = `api/leave`
    }
  async getLeaveData(
    payload: GetLeavePayload
  ): Promise<AxiosResponse<Array<LeaveResponse>>> {
    const params = new URLSearchParams({
      ...(payload.month && { month: payload.month }),
      ...(payload.email && { email: payload.email }),
      ...(payload.status && { status: payload.status }),
    });

    return await apiService.get(`${this.url}/data/${payload.year}?${params.toString()}`);
  }

  async addLeave(payload: LeavePayload): Promise<AxiosResponse<LeaveResponse>> {
    return await apiService.post(this.url, payload)
  }

  async updateLeave(payload: UpdatePayload): Promise<AxiosResponse<LeaveResponse>> {
    return await apiService.put(`${this.url}/${payload.id}`, payload);
  }

  async deleteLeave(id: number): Promise<AxiosResponse> {
    return await apiService.delete(`${this.url}/${id}`);
  }
}

const leaveManager = new LeaveManager();
export default leaveManager;