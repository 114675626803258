/**
 * Sorts an array of objects based on a specified key.
 *
 * @param {Array} array - The array of objects to be sorted.
 * @param {string} key - The key to sort the objects by.
 * @return {Array} - The sorted array.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
const sortByKey = (array: any, key: any): Array<any> => {
  return array.sort((a: any, b: any) => {
    const keyA = a[key].toLowerCase();
    const keyB = b[key].toLowerCase();
    if (keyA < keyB) {
      return -1;
    }
    if (keyA > keyB) {
      return 1;
    }
    return 0;
  });
};
// Example
// const sortedArray = sortByKey(array, "label");
// console.log(sortedArray);

export { sortByKey };
