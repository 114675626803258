import React, { useState } from "react";

type Tab = {
  label: string;
  content: React.ReactNode;
};

interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="flex space-x-6 border-b border-gray-200">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`pb-2 font-semibold ${activeTab === index ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-500"}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="mt-4">{tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
